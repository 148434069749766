import SideDrawer from "../sideDrawer/sideDrawer";
import ProfileLogo from "./profileLogo";

const layout = (props) => {
  return (
    <div className="flex min-h-screen bg-indigo">
      
      <SideDrawer />
      

      <div className="flex-col w-full bg-gray-100 rounded-tl-large pt-6 pl-12 pr-10">
        <div className="flex">
            <div className="flex-grow"></div>
            <ProfileLogo/>
        </div>
        {props.children} 
        <div className="flex-grow-0 ">{" "}</div>
         <div className="flex text-sm text-gray-600 space-x-10 mt-6 p-2">
             <h1>Legal</h1>
             <h1>Privacy Policy</h1>
             <h1>Support</h1>
             <div className="flex-grow">{" "} </div>
             <h1>© 2021 Stegofy, All rights reserved</h1>
         </div>           
      </div>
    </div>
  );
};

export default layout;
