import axios from "../../../axios/axios";
import React, { useContext, useState,useEffect,useRef } from "react";
import AuthContext from "../../../context-store/auth-context";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const SignUpStep2 = ({ navigation }) => {
  const authCtx = useContext(AuthContext);
  const [emailOTP, setEmailOTP] = useState('');
  const [phoneOTP, setPhoneOTP] = useState(null);
  const [phoneOtpError, setphoneOtpError] = useState('')
  const [emailOtpError, setemailOtpError] = useState('')
  const otpPhoneVal = useRef(null);
  const otpEmailVal = useRef(null);
  const [emailSeconds, setemailSeconds] = useState(90);
  const [phoneSeconds, setphoneSeconds] = useState(90);
  const [emailtimerStatus, setemailtimerStatus] = useState(true)
  const [phonetimerStatus, setphonetimerStatus] = useState(true)
  const [host, sethost]= useState('')
  // http://localhost:5000
  const [toSend, setToSend] = useState({
    to_name: authCtx.userName,
    otp:otpPhoneVal.current,
    reply_to: authCtx.email,
  });
  
  const [statuspop, setstatuspop] = useState({
    open: false,
    text: "",
    color: "",
  });

  useEffect(() => {

    var digits = '0123456789';
    var OTP = '';
    for (let i = 0; i < 6; i++ ) {
        OTP += digits[Math.floor(Math.random() * 10)];
    }
    otpPhoneVal.current=OTP;
    setToSend({
      ...toSend,
      otp:otpPhoneVal.current,
    })
    
   
  },[]);
  
  useEffect(() => {
  
    var digits = '0123456789';
    var OTP = '';
    for (let i = 0; i < 6; i++ ) {
        OTP += digits[Math.floor(Math.random() * 10)];
    }
   
    otpEmailVal.current=OTP;
   
  }, []);

  const resendPhoneOtp = async () => {
    setphonetimerStatus(true);
    setphoneSeconds(90);

  authCtx.authmobOtp=otpPhoneVal.current

  await axios
  .get(`/api/sendOtp/${authCtx.phone_number}/${toSend.otp}`)
  .then((response) => {
    console.log(response.data);
  }).catch( error => console.log(error));

  };
 
  const resendEmailOtp = () => {
     setemailtimerStatus(true);
    setemailSeconds(90);
    authCtx.authemailOtp=otpEmailVal.current
   axios.post(`${host}/api/sendMail`,toSend)
   .then(response=>{
     console.log(response.data)
   })
   .catch(err =>{
     console.log(err);
   })

  };

   const Signup = async () => {
    const signUpData = {
      password: authCtx.password,
      phone_number: authCtx.phone_number,
      email: authCtx.email,
      name: authCtx.name,
    };

    console.log("data", signUpData);

    await axios
      .post("/signup", signUpData)
      .then((response) => {
        console.log("successfully signup", response.data);
        authCtx.user_id = response.data.id;
        localStorage.setItem("mfr_id", response.data.id);
        //console.log(authCtx.user_id);
      }).catch( error => console.log(error));
  };

  const verifyOTP = () => {
    
    if(phoneOTP==authCtx.authmobOtp && phoneOTP != null){
       Signup()
       setemailOtpError(false);
       setphoneOtpError(false);
       navigation.go("step4");
    }

  else {
    setemailOtpError(true);
    setphoneOtpError(true);

    setstatuspop({
      color: "error",
      open: true,
      text: "Enter Valid Mobile and Email OTP",
    });
  
  }
}

 
useEffect(() => {

  if (phoneSeconds > 0 && phonetimerStatus==true) {
    setTimeout(() => setphoneSeconds(phoneSeconds - 1), 1000);
  } else {
    setphoneSeconds(0);
    setphonetimerStatus(false)
    authCtx.authmobOtp='';
    
  }
 
})

useEffect(() => {

  if (emailSeconds > 0 && emailtimerStatus==true) {
    setTimeout(() => setemailSeconds(emailSeconds - 1), 1000);
  } else {
    setemailSeconds(0);
    setemailtimerStatus(false)
    authCtx.authemailOtp='';
  }
 
})


  return (
    <div className="mt-40 sm:mt-5 mob:mt-3 w-input w-auto sm:text-center sm:my-auto  md:text-center mob:w-auto">

{statuspop && (
            <div>
              <Snackbar
                open={statuspop.open}
                TransitionComponent="SlideTransition"
                autoHideDuration={6000}
                onClose={() => {
                  setstatuspop({
                    ...statuspop,
                    open: false,
                  });
                }}
              >
                <Alert
                  onClose={() => {
                    setstatuspop({
                      ...statuspop,
                      open: false,
                    });
                  }}
                  severity={statuspop.color}
                  sx={{ width: "100%" }}
                >
                  {statuspop.text}
                </Alert>
              </Snackbar>
            </div>
          )}
        <p
          className="text-gray-600 font-poppins cursor-pointer hover:text-gray-900 py-2  mob:ml-9"
          onClick={() => navigation.go("SignUpStep1")}
        >
          {"< Back"}
        
        </p>
        <div className="mob:text-center ">
        <p className="text-black text-4xl w-full font-poppins px-8 justify-items-center mb-12 mob:mb-8 mob:text-2xl">
          Welcome to <strong>Stegofy</strong> 👋
        </p>

        <div className="relative flex w-full flex-wrap items-stretch mb-3 sm:justify-center  mob:justify-center ">
          <input
            type="text"
            // className="input mob:w-11/12 mob:text-base sm:w-3/4 mob:w-auto mob:text-base"
            className={phoneOtpError&&Boolean(phoneOTP)?"input mob:w-11/12 mob:text-base sm:w-3/4 mob:w-auto mob:text-base border-red-400 hover:border-red-400":Boolean(phoneOTP)?"input mob:w-11/12 mob:text-base sm:w-3/4 mob:w-auto mob:text-base hover:border-green-300 focus:border-green-300":"input mob:w-11/12 mob:text-base sm:w-3/4 mob:w-auto mob:text-base border-grey-500 hover:border-grey-500 focus:border-indigo-300"}
            name="phoneOTP"
            onChange={(e) => setPhoneOTP(e.target.value) }
            placeholder="Enter Mobile OTP"
          />
          <span
            onClick={resendPhoneOtp}
            className=" cursor-pointer absolute inset-y-0 right-0 text-blue-600 hover:text-blue-800  h-full font-semibold text-center bg-transparent text-base items-center justify-center py-4 pr-2 sm:mr-14 sm:pr-5 mob:w-auto mob:text-xs mob:mt-1 mob:mr-4 "
          >
            {phonetimerStatus ? "" : "Resend OTP"}
          </span>
          <div className="ml-3 opacity-60">
          Please enter the OTP send on mobile in <span className="text-xl text-indigo-600">{phoneSeconds}s</span>
        </div>
        </div>

        {/* <div className="relative flex w-full flex-wrap items-stretch mb-3 sm:justify-center mob:justify-center ">
          <input
            type="text"
            className={emailOtpError && Boolean(emailOTP)?"input mob:w-11/12 mob:text-base sm:w-3/4 mob:w-auto mob:text-base border-red-400 hover:border-red-400":Boolean(emailOTP)?"input mob:w-11/12 mob:text-base sm:w-3/4 mob:w-auto mob:text-base hover:border-green-300 focus:border-green-300":"input mob:w-11/12 mob:text-base sm:w-3/4 mob:w-auto mob:text-base border-grey-500 hover:border-grey-500 focus:border-indigo-300"}
            name="emailOTP"
            onChange={(e) => setEmailOTP(e.target.value) }
            placeholder="Enter Email OTP"
          />
          <span
            onClick={resendEmailOtp}
           
             className=" cursor-pointer absolute inset-y-0 right-0 text-blue-600 hover:text-blue-800  h-full font-semibold text-center bg-transparent text-base items-center justify-center py-4 pr-2 sm:mr-14 sm:pr-5 mob:w-auto mob:text-xs mob:mt-1 mob:mr-4"
          >
            {emailtimerStatus ? "" : "Resend OTP"}
          </span>
          <div className="ml-3 opacity-60">
          Please enter the OTP on Email in <span className="text-xl text-indigo-600">{emailSeconds}s</span>
        </div>
        </div> */}
       

        <button className="btn btn-blue sm:w-3/4 mob:w-11/12 mob:text-base" onClick={verifyOTP}>
          signup
        </button>
        <button
          className="btn-default w-full py-2 mt-4 sm:w-3/4 mob:w-11/12 mob:text-base"
          onClick={() => navigation.go("step1")}
        >
          <p className="text-lg ">Existing User? Log in </p>
        </button>
      </div>
    
   
    </div>
  );
};

export default SignUpStep2;
