import React, { useState } from "react";
import { DownArrow, UpArrow } from "../../../SVG/svg"

const TableRow = ({
  product
}) => {
  const [isActive, setIsActive] = useState(false);
  

  const RowItem = ( <tr>
      <td colSpan="6">
      <img className="w-full" src="/image/track-trace.png" alt="track-trace" />
      </td>
  </tr> )

  return (
    <>
      <tr className="divide-y divide-gray-200">
        <td className="pl-8 py-4 whitespace-nowrap">
          <div className="text-sm  text-gray-600">{product.id}</div>
        </td>
        <td className="pl-4 py-4 whitespace-nowrap">
          <div className="items-center">
            <div className="text-sm font-bold text-blue-400">
              {product.name}
            </div>
          </div>
        </td>
        <td className="pl-4 py-4 whitespace-nowrap  text-gray-600">
          {product.quantity}
        </td>
        <td className="pl-4 py-4 flex space-x-3 whitespace-nowrap font-medium text-gray-600">
          <span className="w-2 h-2 px-1 mt-2 inline-flex text-xs leading-5 font-semibold rounded-2lg bg-green-300 text-green-300"></span>
          <p>{product.status}</p>
        </td>
        <td className=" pl-4 py-4 whitespace-nowrap font-medium text-gray-600">
          <p className="px-2">{product.delivery} </p>
        </td>
        <td colSpan="1">
          <div
            onClick={() => setIsActive(!isActive)}
            className="hover:text-gray-600"
          >
            {isActive ? <UpArrow /> : <DownArrow/>}
          </div>
        </td>
      </tr>
      {isActive && RowItem}
    </>
  );
};

export default TableRow;
