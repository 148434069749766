import React from "react";
// import { Upload } from '../../../SVG/svg';
import Layout from "../layout/layout";
import Export from "../../customElement/export";
import Filter from "../../customElement/filter";
import Grid from "../../customElement/grid";
import Search from "../../customElement/search";

const Customers = () => {
  const product = [
    {
      id: "1234",
      name: "Sachin Gupta",
      phone: "+91-9876543210",
      totalPurchase: "₹65,999",
      date: "28-5-2021",
      since: "28-02-2021",
      item: "24",
    },
    {
      id: "12345",
      name: "Ravi Teja",
      phone: "+91-9876543210",
      totalPurchase: "₹65,999",
      date: "28-5-2021",
      since: "28-02-2021",
      item: "24",
    },
    {
      id: "12342",
      name: "Pooja verma",
      phone: "+91-9876543210",
      totalPurchase: "₹65,999",
      date: "28-5-2021",
      since: "28-02-2021",
      item: "24",
    },
    {
      id: "1235",
      name: "Karthik Kumar",
      phone: "+91-9876543210",
      totalPurchase: "₹65,999",
      date: "28-5-2021",
      since: "28-02-2021",
      item: "24",
    },
  ];

  return (
    <Layout>
      <div className="flex">
        <div className="heading">Customers</div>
      </div>
      <div className="flex flex-col mt-14">
        <div className="-my-2 overflow-x-auto ">
          <div className="py-2 align-middle inline-block min-w-full  ">
            <div className="shadow overflow-hidden border-b border-gray-200 rounded-layout divide-y-2 divide-gray-200">
              <div className="bg-white flex w-full">
                <div className="flex-grow ">
                  <Search />
                </div>
                <Export />
                <Filter />
                <Grid />
                <div className="w-6"></div>
              </div>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-white">
                  <tr>
                    <th scope="col" className="">
                      <input type="checkbox" className="w-5 h-5 rounded-3lg " />
                    </th>
                    <th scope="col" className="table-head">
                      Customer id
                    </th>
                    <th scope="col" className="table-head">
                      Name
                    </th>
                    <th scope="col" className="table-head">
                      Phone
                    </th>
                    <th scope="col" className="table-head">
                      total purchases
                    </th>
                    <th scope="col" className="table-head">
                      Last purchase on
                    </th>
                    <th scope="col" className="table-head">
                      Customer since
                    </th>
                    <th scope="col" className="table-head">
                      items purchased
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {product.map((product) => (
                    <tr key={product.id}>
                      <td className="pl-4 py-4 whitespace-nowrap">
                        <div>
                          <input type="checkbox" className=" w-5 h-5" />
                        </div>
                      </td>
                      <td className="pl-4 py-4 whitespace-nowrap">
                        <div className="text-sm  text-gray-600">
                          {product.id}
                        </div>
                      </td>
                      <td className="pl-4 py-4 whitespace-nowrap">
                        <div className="items-center">
                          <div className="text-sm font-bold text-gray-600">
                            {product.name}
                          </div>
                        </div>
                      </td>
                      <td className="pl-4 py-4 whitespace-nowrap  text-gray-600">
                        {product.phone}
                      </td>
                      <td className="flex pl-4 py-4 whitespace-nowrap font-medium text-gray-600">
                        <p className="px-2">{product.totalPurchase} </p>
                      </td>
                      <td className="pl-4 py-4 whitespace-nowrap font-medium text-gray-600">
                        {product.date}
                      </td>
                      <td className="pl-4 py-4 whitespace-nowrap font-medium text-gray-600">
                        {product.since}
                      </td>
                      <td className="pl-4 py-4 whitespace-nowrap font-medium text-gray-600">
                        {product.item}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Customers;
