import Layout from "../layout/layout";
import { useHistory } from "react-router-dom";

const Campaign = () => {
const history = useHistory(); 
    const createPage = () =>{
        history.push("/create-campaign")
    }

  return (
    <Layout>
        <div className="flex space-x-4 mb-3">
      <div className="heading">Campaign</div>
      <div className="btn-default py-2 px-4" onClick = {createPage} >
          Create Campaign
      </div>

        </div>
      <img src="/image/campaign.png" alt="Loyalty" />
    </Layout>
  );
};

export default Campaign;
