import React from 'react';

const ProductSuggestions = ({setSuggestionHandler, suggestionInfo}) => {
    return (
        <div className="bg-white rounded-2lg mt-10 w-1/2 px-8 py-7 mb-28">
            <div className="text-lg font-poppins font-semibold text-gray-700 pb-4">Product Suggestions</div>
            <div className="py-2">
                <p className="text-gray-600 pb-2" >Serve with</p>
                <input className="input-field" type="text" value={suggestionInfo.serve_with} name="serve_with" onChange={setSuggestionHandler} />
            </div>
            <div className="py-2">
                <p className="text-gray-600 pb-2" >Suggest product</p>
                <input className="input-field" value={suggestionInfo.suggest_product } name="suggest_product" onChange={setSuggestionHandler} type="text" />
            </div>
            <div className="py-2">
                <p className="text-gray-600 pb-2" >Can be used with</p>
                <input className="input-field" type="text" value={suggestionInfo.used_with} name="used_with" onChange={setSuggestionHandler} />
            </div>
        </div>
    );
};

export default ProductSuggestions;