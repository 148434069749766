import React from "react";
// import { Upload } from '../../../SVG/svg';
import Layout from "../layout/layout";
import Filter from "../../customElement/filter";
import Search from "../../customElement/search";
import TableRow from "./TableRow";

const TrackTrace = () => {
  const product = [
    {
      id: "ABCD1234",
      name: "Peanut Butter",
      quantity: "1000",
      status: "Shipped",
      delivery: "Monday, March 22 2021",      
    },
    {
        id: "ABCD1235",
        name: "Wild Honey",
        quantity: "1000",
        status: "Shipped",
        delivery: "Monday, March 22 2021",      
      },
      {
        id: "ABCD1236",
        name: "Groundnut Oil",
        quantity: "1300",
        status: "Shipped",
        delivery: "Monday, March 22 2021",      
      },
      {
        id: "ABCD1237",
        name: "Orange Juice",
        quantity: "1200",
        status: "Shipped",
        delivery: "Monday, March 22 2021",      
      },      
  ];

  return (
    <Layout>
      <div className="flex">
        <div className="heading">Track & Trace</div>
      </div>
      <div className="flex flex-col mt-14">
        <div className="-my-2 overflow-x-auto ">
          <div className="py-2 align-middle inline-block min-w-full  ">
            <div className="shadow overflow-hidden border-b border-gray-200 rounded-layout divide-y-2 divide-gray-200">
              <div className="bg-white flex w-full">
              <div className=" flex-grow flex ">
                  <div className="flex-grow">
                  <Search />
                  </div>
                  <div className="text-center text-lg my-8 ml-1 mr-24 ">
                    {4} Products Total{" "}
                  </div>
                </div>
                <Filter />
                <div className="w-8" ></div>            
              </div>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-white">
                  <tr>                   
                    <th scope="col" className="table-head">
                      Product Code
                    </th>
                    <th scope="col" className="table-head">
                      Product Name
                    </th>
                    <th scope="col" className="table-head">
                      Quantity
                    </th>
                    <th scope="col" className="table-head">
                      Status
                    </th>
                    <th scope="col" className="table-head">
                      Expected Delivery
                    </th>
                    <th> </th>                                      
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {product.map((product,idx) => (
                    <TableRow key={idx} product={product}/>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TrackTrace;
