import { useHistory} from 'react-router-dom' 

// import styles from './waitingPage.module.css';

const WaitingPage = () =>{

    const history = useHistory();
    const onClickHandler = () =>{
        history.push("/profile");
    }
    return (
        <div className="border-2 border-green-700 align-middle bg-green-100 m-5 p-2 w-1/2">
            <h2>Registration successfully Done.</h2>
            <h3>Waiting for Profile Approval. </h3>
            <button className="btn btn-blue-500" onClick={onClickHandler}> Approved </button>           
        </div>
    );
}

export default WaitingPage;