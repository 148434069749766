import {useHistory} from "react-router-dom";
import {useContext, useState} from "react";
import AuthContext from "../../../context-store/auth-context";

const Step2 = ({ navigation}) => {

    const authCtx = useContext(AuthContext);

const history  = useHistory();
const [OTP,setOTP]  =useState(null);

const resendOtp = () =>{
    console.log("Resend Otp")
}

const userConformation = () =>{    
    if(OTP !== null){
        history.push("/wait");
    }else{
        alert("Enter OTP")
    }    
}

    return (
      <div className="mt-44 w-input">
        <div className="">
          <p className="text-black text-4xl w-full font-poppins px-8 justify-items-center mb-8">
            Welcome to <strong>Stegofy</strong> 👋
          </p>
          <div className="font-sans text-lg text-gray-600 text-center">
        {"Please enter the OTP sent to"}<br/>
        <div className="flex justify-between text-gray-700 font-semibold py-2 pb-4">
        <p>+91 | {authCtx.phone_number} </p>
        <button
          className="text-blue-600 font-semibold hover:text-blue-800 focus:outline-none"
          onClick={() => navigation.go("step1")}
        >
          {"Change ?"}
        </button>
        </div>
      </div>
          <div className="relative flex w-full flex-wrap items-stretch mb-3">
          <input
            type="text"
            className="input"
            name="compName"
            onChange={(e) => setOTP(e.target.value) }
            placeholder="Enter OTP"
          />
          <span
          onClick={resendOtp}
          className=" cursor-pointer absolute inset-y-0 right-0 text-blue-600 hover:text-blue-800  h-full font-semibold text-center bg-transparent text-base items-center justify-center py-4 pr-2"
        >
         Resend OTP
        </span>
          </div>
          
          <button className="btn btn-blue" onClick={ userConformation }>
            Verify Otp
          </button>
        
        </div>
      </div>
    );
  };
  
  export default Step2;
  