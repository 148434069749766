import React, { useState } from "react";
import axios from "../../../../../axios/axios";

import { Edit, DargDrop, DeleteSVG, Check } from "../../../../../SVG/svg";

const Vitamins = ({ vitaminInfo, flag, deleteItem }) => {
  
  const [edit, setEdit] = useState(false);
  const [bgColor, setBgColor] = useState(null);

  const [vitaminItem, setVitaminsItem] = useState({
    ...vitaminInfo,
  });

  const updateItem = async (id) => {
      
      await axios
        .put(`/variant/vitamins`, vitaminItem)
        .then((response) => {
            //alert("Item Updated successfully");
        })
        .catch((error) => console.log(error));

    setBgColor(null);
    setEdit(false);

  };

  const editHandler = () => {
    setBgColor("white");
    setEdit(true);
  };

  const vitaminInfoHandler = (event) => {
    setVitaminsItem({
      ...vitaminItem,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <>
      <tr key={vitaminInfo.name}>
        <td className="px-2 py-4 whitespace-nowrap">
          <div className="flex font-semibold text-sm  font-poppins text-gray-700  ">
            <DargDrop />
            {/* <p className="pl-2"> {vitaminItem.name}</p> */}
            <input
              disabled={!edit}
              type="text"
              name="name"
              value={vitaminItem.name}
              onChange={vitaminInfoHandler}             
            className="appearance-none font-semibold ml-4 p-1 rounded-md bg-white"
            />
          </div>
        </td>
        <td className="px-3 py-4 whitespace-nowrap ">
          <div className="">
            <input
              disabled={!edit}
              type="number"
              name="value_intake"
              value={vitaminItem.value_intake}
              onChange={vitaminInfoHandler}
              style={{ backgroundColor: bgColor }}
              className={`input-field text-sm font-poppins text-gray-700`}
            />
          </div>
        </td>
        <td className="px-3 py-4 whitespace-nowrap text-sm font-poppins text-gray-700">
          <div className="">
            <select
              disabled={!edit}
              style={{ backgroundColor: bgColor }}
              className="input-field"
              name="precision_value"
              value={vitaminItem.precision_value}
              onChange = { vitaminInfoHandler }
            >
              <option>%</option>
              <option>Kcal</option>
              <option>Kg</option>
              <option>gm</option>
              <option>mg</option>
              <option>mcg</option>
              <option>Liter</option>
              <option>ml</option>
            </select>
          </div>
        </td>
        <td className="px-3 py-4 whitespace-nowrap ">
          <div className="">
            <input
              disabled={!edit}
              style={{backgroundColor: bgColor}}
              type="number"
              name="quantity_contained"
              onChange={vitaminInfoHandler}
              value={vitaminItem.quantity_contained}
              className="input-field text-sm font-poppins text-gray-700"
            />
          </div>
        </td>
        <td className="px-3 py-4 whitespace-nowrap">
          <div className="">
            <select
            disabled={!edit}
            style={{backgroundColor: bgColor}}
              className="input-field"
              name="measurement_unit"
              value={vitaminItem.measurement_unit}
              onChange = { vitaminInfoHandler } >
              <option>%</option>
              <option>Kcal</option>
              <option>Kg</option>
              <option>gm</option>
              <option>mg</option>
              <option>mcg</option>
              <option>Liter</option>
              <option>ml</option>
            </select>
          </div>
        </td>
        <td className="flex py-4 px-2">
          {edit ? (
            <button
              className="btn-default p-1"
              onClick={() => updateItem(vitaminItem.id)}
            >
              <Check />
            </button>
          ) : (
            <div
              className="p-1 rounded-md cursor-pointer hover:bg-gray-200"
              onClick={editHandler}
            >
              <Edit />
            </div>
          )}
          <div
            className="mx-1 p-1 rounded-md cursor-pointer hover:bg-gray-200"
            onClick={() => deleteItem(vitaminItem.id)}
          >
            <DeleteSVG />
          </div>
        </td>
      </tr>
      {flag ? (
        <tr>
          <td colSpan="1"></td>
          <td colSpan="2">
            No Allergens Information found of this props.vitaminInfo
          </td>
        </tr>
      ) : null}
    </>
  );
};

export default Vitamins;
