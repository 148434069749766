import React, { useState } from "react";
// import { Upload } from '../../../SVG/svg';
import Layout from "../layout/layout";

import Search from "../../customElement/search";
import Modal from "../../customElement/modal/userModal";

const Users = () => {
  const user = [    
    {
        id: "124",
        name: "Sachin Gupta",
        email:"akshay@mail.com",
        permission:"dashbord"
      },
      {
        id: "123",
        name: "Sachin Gupta",
        email:"akshay@mail.com",
        permission:"dashbord"
      },
      {
        id: "1134",
        name: "Sachin Gupta",
        email:"akshay@mail.com",
        permission:"dashbord"
      },
      {
        id: "134",
        name: "Sachin Gupta",
        email:"akshay@mail.com",
        permission:"dashbord"
      },
  ];

  const [showModal, setShowModal] = useState(false);

  const modalCancelHandler = () => {
    setShowModal(false);
  };

  const modalSaveHandler = () => {
    setShowModal(false);
  };

  return (
    <Layout>
      <div className="flex">
        <div className="heading">Users</div>
        <button
          className="flex btn-default py-1 px-8 pl-4 ml-10"
          onClick={() => setShowModal(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <p className="px-2" style={{ paddingTop: "2px" }}>
            Add User
          </p>
        </button>
      </div>
      <div className="flex flex-col mt-14">
        <div className="-my-2 overflow-x-auto ">
          <div className="py-2 align-middle inline-block min-w-full  ">
            <div className="bg-white shadow overflow-hidden border-b border-gray-200 rounded-layout divide-y-2 divide-gray-200">
              <div className=" w-1/2">
                <Search />
              </div>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-white">
                  <tr>
                    <th scope="col" className="-ml-4">
                      <input type="checkbox" className="w-5 h-5 -ml-14" />
                    </th>

                    <th scope="col" className="table-head">
                      Name
                    </th>
                    <th scope="col" className="table-head">
                      email
                    </th>
                    <th scope="col" className="table-head">
                      permissions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {user.map((user) => (
                    <tr key={user.id}>
                      <td className="pl-4 py-4 whitespace-nowrap">
                        <div>
                          <input type="checkbox" className=" w-5 h-5" />
                        </div>
                      </td>

                      <td className="pl-4 py-4 whitespace-nowrap">
                        <div className="items-center">
                          <div className="text-sm font-bold text-gray-600">
                            {user.name}
                          </div>
                        </div>
                      </td>
                      <td className="pl-4 py-4 whitespace-nowrap  text-gray-600">
                        {user.email}
                      </td>
                      <td className="flex pl-4 py-4 whitespace-nowrap font-medium text-gray-600">
                        <p className="px-2">{user.permission} </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        cancel={modalCancelHandler}
        show={showModal}
        save={modalSaveHandler}
        title="Add New User"
      >
        <div className="flex-col space-y-4 text-gray-700">
          <div className="grid grid-cols-2 gap-4">
            <div className="lg:w-99 sm:w-auto">
              <p className="py-2">Name</p>
              <input className="input-field" />
            </div>
            <div>
              <p className="py-2">Email</p>
              <input className="input-field" />
            </div>
            <div>
              <p className="py-2">Password</p>
              <input className="input-field" />
            </div>
            <div>
              <p className="py-2">Re-enter Password</p>
              <input className="input-field" />
            </div>
          </div>
          <div className="text-gray-700">
            <p className="mb-4">Permissions</p>
            <div className="grid grid-cols-3 gap-4">
              <div className="flex space-x-2">
                <input type="checkbox" className=" mt-1 w-4 h-4" />
                <p>Manage Products</p>
              </div>
              <div className="flex space-x-2">
                <input type="checkbox" className=" mt-1 w-4 h-4" />
                <p>Manage Analytics</p>
              </div>
              <div className="flex space-x-2">
                <input type="checkbox" className=" mt-1 w-4 h-4" />
                <p>Manage Track & Trace</p>
              </div>
              <div className="flex space-x-2">
                <input type="checkbox" className=" mt-1 w-4 h-4" />
                <p>Review Manager</p>
              </div>
              <div className="flex space-x-2">
                <input type="checkbox" className=" mt-1 w-4 h-4" />
                <p>Support/Warranty</p>
              </div>
              <div className="flex space-x-2">
                <input type="checkbox" className=" mt-1 w-4 h-4" />
                <p>Serialized QR</p>
              </div>
              <div className="flex space-x-2">
                <input type="checkbox" className=" mt-1 w-4 h-4" />
                <p>Manage Campaign</p>
              </div>
              <div className="flex space-x-2">
                <input type="checkbox" className=" mt-1 w-4 h-4" />
                <p>Manage Customers</p>
              </div>
              <div className="flex space-x-2">
                <input type="checkbox" className=" mt-1 w-4 h-4" />
                <p>Manage Users</p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default Users;
