const Export = () => {
  return (
    <div>
      <button className="flex space-x-2 btn-default w-40 px-10 py-1.5 mx-2 mt-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"
          />
        </svg>

        <p className="pt-0.5 font-medium ">Export</p>
      </button>{" "}
    </div>
  );
};

export default Export;
