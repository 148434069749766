import { Upload } from "../../../SVG/svg";
import ImagePicker from "../../customElement/imagePicker";

const Step5 = ({ navigation, setSignUpData, submitHandler }) => {
  return (
    <div className="mt-20 w-input">
        <p className="text-gray-600 font-poppins cursor-pointer hover:text-gray-900 py-2" onClick={() => navigation.go("step4")} >{"< Back"}</p>
      <div className=" ">
        <p className="text-black text-4xl w-full font-poppins px-8 justify-items-center mb-10">
          {" "}
          Enter company details
        </p>
        <div className="flex space-x-2 text-center mb-3" >
          <ImagePicker>
            <div className="p-8" >
              <Upload />
            </div>
          </ImagePicker>
          <p className="text-base text-gray-500 font-poppins font-semibold m-auto" >Upload Company Logo </p>
        </div>

        <select
          type="textarea"
          className="input"
          name="businessType"
          onChange={setSignUpData}
          placeholder=" Company Address"
        >
          <option>Choose business type</option>
          <option>business 1</option>
          <option>business 2</option>
          <option>business 3</option>
        </select>
        <select
          type="text"
          className="input"
          name="industry"
          onChange={setSignUpData}
          placeholder=" Company Address"
        >
          <option>Select industry</option>
          <option>industry1</option>
          <option>industry2</option>
        </select>

        <input
          type="text"
          className="input"
          name="fssaiNumber"
          onChange={setSignUpData}
          placeholder="FSSAI Number (if applicable)"
        />
        <input
          type="text"
          className="input"
          name="panNumber"
          onChange={setSignUpData}
          placeholder="Company PAN number (optional)"
        />

        <input
          type="text"
          className="input"
          name="gstNumber"
          onChange={setSignUpData}
          placeholder="Company GSTIN number"
        />
        <button className="btn btn-blue" onClick={submitHandler}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default Step5;
