import React, { useState, useEffect } from "react";
import axios from "../../../axios/axios";

import Layout from "../layout/layout";
import UnitRow from "./unitRow";
import Search from "../../customElement/search";
import Modal from "../../customElement/modal/userModal";

const Units = () => {
  const [showModal, setShowModal] = useState(false);
  const [unitList, setUnitList] = useState([]);

  const modalCancelHandler = () => {
    setShowModal(false);
  };

  const [unit, setUnit] = useState({
    mfr_id: localStorage.getItem("mfr_id"),
  });

  const setUnitHandler = (event) => {
    setUnit({
      ...unit,
      [event.target.name]: event.target.value,
    });
  };

  const modalSaveHandler = async () => {
    
    await axios
      .post(`/units`, unit)
      .then((response) => {
        setUnitList(response.data);
        // console.log("date post", unitList);
      })
      .catch((error) => console.log(error));
    setShowModal(false);
    setUnit({
      mfr_id: localStorage.getItem("mfr_id"),
    });
  };

  useEffect(() => {
    (async () => {
      const mfr_id = localStorage.getItem("mfr_id");
      await axios
        .get(`/units/${mfr_id}`)
        .then((response) => {
          setUnitList(response.data);
        })
        .catch((error) => console.log(error));
    })();
  }, []);

  return (
    <Layout>
      <div className="flex">
        <div className="heading">Units</div>
        <button
          className="flex btn-default py-1 px-8 pl-4 ml-10"
          onClick={() => setShowModal(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <p className="px-2" style={{ paddingTop: "2px" }}>
            Add Units
          </p>
        </button>
      </div>
      <div className="flex flex-col mt-14">
        <div className="-my-2 overflow-x-auto ">
          <div className="py-2 align-middle inline-block min-w-full  ">
            <div className="bg-white shadow overflow-hidden border-b border-gray-200 rounded-layout divide-y-2 divide-gray-200">
              <div className=" w-1/2">
                <Search />
              </div>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-white">
                  <tr>
                    <th scope="col" className="">
                      <input type="checkbox" className="w-5 h-5 -ml-3" />
                    </th>

                    <th scope="col" className="table-head">
                      Name
                    </th>
                    <th scope="col" className="table-head">
                      Address
                    </th>
                    <th scope="col" className="table-head">
                      {}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {unitList.map((item) => (
                    <UnitRow key={item.id} item={item} setUnitList={setUnitList} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        cancel={modalCancelHandler}
        show={showModal}
        save={modalSaveHandler}
        title="Add New Unit"
      >
        <div className="text-gray-700">
          <div className="grid grid-cols-1 gap-4">
            <div className="lg:w-99 sm:w-auto">
              <p className="py-2">Name</p>
              <input
                className="input-field"
                name="name"
                onChange={setUnitHandler}
              />
            </div>
            <div>
              <p className="py-2">Address</p>
              <textarea
                className="textarea-field"
                name="address"
                onChange={setUnitHandler}
              />
            </div>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default Units;
