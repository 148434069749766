import { NavLink } from "react-router-dom";
import { Star } from "../../../SVG/svg";
import "./sideDrawer.css"

const sideDrawer = () => {

    return (
    <div className="w-1/5 text-white ">
      <NavLink to="/" className="flex my-10 pl-8 ">
        <img
          src="/sidebar/stegofy-logo.png"
          alt="stegofy"
          width="43"
          height="43"
        />
        <h1 className="text-3xl font-medium font-poppins p-1"> Stegofy</h1>
      </NavLink>
      <NavLink to="/dashbord" exact activeClassName="activeStyle" className="side-item flex">
        <img
          src="/sidebar/item1-24x24.png"
          alt="stegofy"
          width="21"
          height="21"
        />
        <p className="pl-2">Dashbord</p>
      </NavLink>
      <NavLink to="/products" exact activeClassName="activeStyle" className="side-item flex ">
      {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="gray" >
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.48614 21.4998H15.834C18.9003 21.4998 21.2527 20.3922 20.5845 15.9346L19.8065 9.89339C19.3946 7.66913 17.9758 6.81787 16.731 6.81787H6.5525C5.28934 6.81787 3.95295 7.7332 3.47698 9.89339L2.69895 15.9346C2.13144 19.8888 4.41977 21.4998 7.48614 21.4998Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.34877 6.59824C7.34877 4.21216 9.28307 2.27787 11.6691 2.27787V2.27787C12.8181 2.273 13.9218 2.72603 14.7359 3.53679C15.5501 4.34754 16.0078 5.44923 16.0078 6.59824V6.59824" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.70353 11.1017H8.7493" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.5341 11.1017H14.5799" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> */}

<img src="/sidebar/item2.png" alt="stegofy" width="21" height="21" />
        <p className="pl-2">Products</p>
      </NavLink>
      <NavLink to="/analytics" exact className="side-item flex" activeClassName="activeStyle" >
        <img src="/sidebar/item3.png" alt="stegofy" width="21" height="21" />
        <p className="pl-2">Analytics</p>
      </NavLink>
      <NavLink to="/trackTrace" exact activeClassName="activeStyle" className="side-item flex">
        <img src="/sidebar/item4.png" alt="stegofy" width="21" height="21" />
        <p className="pl-2">Track & Trace</p>
      </NavLink>
      <NavLink to="/reviewManager" exact activeClassName="activeStyle" className="side-item flex">
        {/* <img src="/sidebar/item5.png" alt="stegofy" width="21" height="21" /> */}
        <Star/>
        <p className="pl-2">Review Manager</p>
      </NavLink>
      <NavLink to="/supportWarranty" exact activeClassName="activeStyle" className="side-item flex">
        <img src="/sidebar/item6.png" alt="stegofy" width="21" height="21" />
        <p className="pl-2">Support/Warranty</p>
      </NavLink>
      <NavLink to="/QR" exact activeClassName="activeStyle" className="side-item flex">
        <img src="/sidebar/item7.png" alt="stegofy" width="21" height="21" />
        <p className="pl-2">Serialized QRs</p>
      </NavLink>
      <NavLink to="/campaign" exact activeClassName="activeStyle" className="side-item flex">
        <img src="/sidebar/item8.png" alt="stegofy" width="21" height="21" />
        <p className="pl-2">Campaign</p>
      </NavLink>
      <NavLink to="/loyalty" exact activeClassName="activeStyle" className="side-item flex">
        <img src="/sidebar/item12.png" alt="stegofy" width="21" height="21" />
        <span className="pl-2">Loyalty</span>
      </NavLink>
      <NavLink to="/customers" exact activeClassName="activeStyle" className="side-item flex">
        <img src="/sidebar/item9.png" alt="stegofy" width="21" height="21" />
        <p className="pl-2">Customers</p>
      </NavLink>
      <NavLink to="/units" exact activeClassName="activeStyle" className="side-item flex">
        <img src="/sidebar/item11.png" alt="stegofy" width="21" height="21" />
        <p className="pl-2">Units</p>
      </NavLink> 
      <NavLink to="/users" exact activeClassName="activeStyle" className="side-item flex">
        <img src="/sidebar/item10.png" alt="stegofy" width="21" height="21" />
        <p className="pl-2">Users</p>
      </NavLink>      
    </div>
  );
};

export default sideDrawer;
