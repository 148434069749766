import React, { useState, useEffect } from "react";

const AuthContext = React.createContext({
  isLoggedIn: false,
  onLogout: () => {},
  onLogin: (email, password) => {},
  phone_number: null,
  email: null,
  userName: null,
  mfr_id: null,
  authmobOtp:null,
  authemailOtp:null,

});

export const AuthContextProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState(localStorage.getItem("userName"));
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [mfrId, setMfrId] = useState(localStorage.getItem("email"));

  useEffect(() => {
    const storedUserLoggedInInformation = localStorage.getItem("isLoggedIn");

    if (storedUserLoggedInInformation === "1") {
      setIsLoggedIn(true);
    }
  }, []);

  const logoutHandler = () => {
    localStorage.clear();
    setIsLoggedIn(false);
  };

  const loginHandler = (userName, email, mfrId) => {
    localStorage.setItem("isLoggedIn", "1");
    setIsLoggedIn(true);
    localStorage.setItem("userName", userName);
    localStorage.setItem("email", email);
    localStorage.setItem("mfr_id", mfrId);
    setUserName(userName);
    setEmail(email);
    setMfrId(mfrId);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        onLogout: logoutHandler,
        onLogin: loginHandler,
        userName: userName,
        mfr_id: mfrId,
        email: email,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
