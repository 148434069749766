import React from "react";

const RecyclingInformation = ({ recycleInfo, setRecycleInfoHandler }) => {

    // console.log(recycleInfo)

  return (
    <div className="bg-white rounded-2lg mt-10 w-1/2 px-8 py-7 mb-10 font-poppins">
      <div className="text-lg  font-semibold text-gray-700 pb-4">
        Products Recycle Details
      </div>
      <div className="py-2">
        <p className="text-gray-600 pb-2">Recycling type*</p>
        <input
          className="input-field"
          value={recycleInfo.recycling_type}
          type="text"
          name="recycling_type"
          onChange={setRecycleInfoHandler}
        />
      </div>
      <div className="py-2">
        <p className="text-gray-600 pb-2">How to Recycle*</p>
        <textarea
          className="textarea-field"
          type="text"
          value={recycleInfo.how_to_recycle}
          name="how_to_recycle"
          onChange={setRecycleInfoHandler}
        />
      </div>
      <div className="flex space-x-3">
        <div className="w-1/2 py-2">
          <p className="text-gray-600 pb-2">Is it recyclable ?</p>
          <div className="flex space-x-3">
            <input
              className="w-6 h-6 m-1 rounded-lg"
              type="radio"
            //   checked={recycleInfo.recyclable}
              name="recyclable"              
              onChange={setRecycleInfoHandler}
            />
            <p className="text-gray-600 pb-2">Yes</p>
            <input
              className="w-6 h-6 m-1 rounded-lg"
              type="radio"
            //   checked={!recycleInfo.recyclable}
              name="recyclable"
            //   value={1}
              onChange={setRecycleInfoHandler}              
            />
            <p className="text-gray-600 pb-2">No</p>
          </div>
        </div>
        <div className="w-1/2 py-2">
          <p className="text-gray-600 pb-2">Number of times Recyclable</p>
          <div className="relative flex flex-wrap items-stretch">
          <input
            className="input-field"
            name="recyclable_times"
            type="number"
            value={recycleInfo.recyclable_times}
            onChange={setRecycleInfoHandler}
            />
            <span className="absolute inset-y-0 right-0 pr-7 py-2 text-gray-600" >{"Times"}</span>
            </div>
        </div>
      </div>
    </div>
  );
};

export default RecyclingInformation;
