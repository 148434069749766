import { Route, Switch } from "react-router-dom";
import {
  products,
  profile,
  serializedQR,
  analytics,
  dashbord,
  supportWarranty,
  reviewManager,
  campaign,
  customers,
  trackTrace,
  loyalty
} from "./component/Dashbord/index";

import Login from "./component/Login/Login";
import Wait from "./component/waitingPage/waitingPage";
import "./App.css";
import Users from "./component/Dashbord/users/users";
import { PrivateRoute } from "./PrivateRoute/PrivateRoute";
import Units from "./component/Dashbord/units/units";
import Dashbord from "./component/Dashbord/dashbord/dashbord";
import createCampaign from "./component/Dashbord/campaign/createCampaign";

function App() {
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  return (
    <Switch className="font-poppins">
      <Route 
      path="/" exact
      render={(props) => (        
         isLoggedIn === '1' ? (  
            <Dashbord {...props} />
        ) : (  
          <Login {...props}/>
        )
      )}  
   />  
      <PrivateRoute path="/profile" exact component={profile} />
      <PrivateRoute path="/products" exact component={products} />
      <PrivateRoute path="/QR" exact component={serializedQR} />
      <PrivateRoute path="/campaign" exact component={campaign} />
      <PrivateRoute path="/loyalty" exact component={loyalty} />
      <PrivateRoute path="/customers" exact component={customers} />
      <PrivateRoute path="/trackTrace" exact component={trackTrace} />
      <PrivateRoute path="/supportWarranty" exact component={supportWarranty} />
      <PrivateRoute path="/reviewManager" exact component={reviewManager} />
      <PrivateRoute path="/analytics" exact component={analytics} />
      <PrivateRoute path="/dashbord" exact component={dashbord} />
      <PrivateRoute path="/users" exact component={Users} />
      <PrivateRoute path="/units" exact component={Units} />
      <Route path="/wait" exact component={Wait} />
      <Route path="/create-campaign" exact component={createCampaign} />
      <Route path="/dashboard-temp" exact component={dashbord} />
    </Switch>
  );
}

export default App;
