import axios from "../../../../axios/axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hooks-helper";
import { Add, Upload } from "../../../../SVG/svg";
import Cooking from "./TableRow/cooking";

const CookingInformation = () => {
 
  const [cookingInfo, setCookingInfo] = useState([]);

//   const [flag, setFlag] = useState(false);

  const defaultProductData = {
    product_id: localStorage.getItem("current_product_id"),
    step_no: "",
    cooking_step: "",
    video_url: "",
    image_url: "",
    variant_of:0,
  };

  const [cookingData, setCookingData] = useForm(defaultProductData);

  useEffect(() => {
    (async () => {
      const id = localStorage.getItem("current_product_id");
      await axios
        .get(`/productCookingInfo/${id}`)
        .then((response) => {
          setCookingInfo(response.data);
        }).catch( error => console.log(error));
    })();
  }, []);

  const AddRow = async () => {
    if (cookingData.cooking_step && cookingData.step_no) {
      await axios
        .post(`/productCookingInfo`, cookingData)
        .then(async (response) => {
          const id = localStorage.getItem("current_product_id");
          await axios
            .get(`/productCookingInfo/${id}`)
            .then((response) => {
              setCookingInfo(response.data);
            }).catch( error => console.log(error));
        }).catch( error => console.log(error));
    } else {
      alert("enter all field data");
    }
  };

  const updateItem = (id) => {
    console.log("update called", id);
  };

  const deleteItem = async (id) => {
    await axios
      .delete(`/productCookingInfo/${id}`)
      .then(async (res) => {
        const product_id = localStorage.getItem("current_product_id");
        await axios
          .get(`/productCookingInfo/${product_id}`)
          .then((response) => {
            setCookingInfo(response.data);
          }).catch( error => console.log(error));
      }).catch( error => console.log(error)
      );
  };

  return (
    <div className="mt-10">
      <div className="-my-2 overflow-x-auto ">
        <div className="py-2 align-middle inline-block min-w-full  ">
          <div className="shadow overflow-hidden border-b border-gray-200 rounded-3xl">
            <div className="text-lg font-poppins font-semibold text-gray-700 py-4 px-6 bg-white">
              Cooking Instructions
            </div>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-white">
                <tr>
                  <th scope="col" className="table-head">
                    Step No.
                  </th>
                  <th scope="col" className="table-head">
                    Cooking step
                  </th>
                  <th scope="col" className="table-head">
                    Video url
                  </th>
                  <th scope="col" className="table-head">
                    Upload Image
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {cookingInfo.map((item) => (
                  <Cooking
                    key={item.id}
                    cookingInfo={item}
                    deleteItem={deleteItem}
                    updateItem={updateItem}
                    // flag={flag}
                  />
                ))}
                <tr key="key1">
                  <td className="px-3 py-4 w-32 font-semibold">
                    <div className="">
                      <input
                        name="step_no"
                        onChange={setCookingData}
                        placeholder="Step No."
                        className="input-field"
                      />
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap ">
                    <div className=" ">
                      <input
                        name="cooking_step"
                        onChange={setCookingData}
                        placeholder="Add cooking Steps"
                        className="text-gray-900 input-field text-sm font-poppins"
                      />
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm font-poppins text-gray-700">
                    <div className="">
                      <input
                        //   value={product.precision}
                        className="input-field text-sm font-poppins text-gray-700"
                      />
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap ">
                    <div className="">
                      {/* <input
                        //   value={product.quantity}
                        placeholder={<Upload/> }
                          className="input-field text-sm font-poppins text-gray-700"
                        /> */}
                      <button className="flex relative input-field hover:text-blue-700">
                        <div className="relative text-blue-500">
                          <Upload />
                        </div>
                        <p className="absolute px-2 inset-x right-0 text-gray-500 hover:text-gray-500 ">
                          .png, .jpg{" "}
                        </p>
                      </button>
                    </div>
                  </td>
                  <td className="pl-3">
                    <button className="btn-default p-1" onClick={AddRow}>
                      <Add />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookingInformation;
