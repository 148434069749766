import React from "react";
import axios from "../../../../axios/axios";
import { DeleteSVG, Edit } from "../../../../SVG/svg";

const VariantList = ({ variant, setVariantList, viewVariantHandler, code, product_id }) => {

     
   const deleteHandler = async (id) => {
    await axios
      .delete(`/variant/${id}/${product_id}`)
      .then((response) => {
        setVariantList(response.data);
      })
      .catch((error) => console.log(error));
  };

   return (
    <tr key={variant.id}>
      <td colSpan="1" className="pl-4 py-4 whitespace-nowrap"></td>
      <td colSpan="1" className="pl-4 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-500 cursor-default">{code}</div>
      </td>
      <td colSpan="1" className="pl-4 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            <img
              className="h-10 w-10 rounded-md"
              src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60"
              alt=""
            />
          </div>
          <div className="ml-4">
            <div className="text-sm text-blue-400 cursor-pointer hover:text-blue-600" onClick={() => viewVariantHandler(product_id, variant.id)}>
              {variant.name} ( {variant.weight} {variant.weight_unit} )
            </div>
          </div>
        </div>
      </td>
      <td colSpan="1" className=""></td>

      <td colSpan="1" className="flex ml-8 space-x-3 py-4 whitespace-nowrap">
      <div
          className="p-2 rounded-md cursor-pointer hover:bg-gray-200"
          onClick={() => viewVariantHandler(product_id, variant.id)}
          >
          <Edit/>
        </div>
        <div
          className="p-1 rounded-md cursor-pointer hover:bg-gray-200"
          onClick={() => deleteHandler(variant.id)}
        >
          <DeleteSVG />
        </div>
      </td>
        <td colSpan="2" className=""></td>
      {/* <td colSpan="1" className=""></td> */}

    </tr>
  );
};

export default VariantList;
