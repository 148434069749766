import React, { useState, useEffect } from "react";
import axios from "../../../../axios/axios";

//import SelectSearch from "react-select-search";
// import ImagePicker from "../../../customElement/imagePicker";
//import Search from "../../../customElement/search";
import { uploadFile } from "react-s3";
import { DeleteSVG } from "../../../../SVG/svg";
//import SelectSearch from "react-select-search";
//import fuzzySearch from "../../../customElement/fuzzySearch/fuzzySearch";

const S3_BUCKET = "stegofy-storage-001";
const REGION = "ap-south-1";
const ACCESS_KEY = "AKIAR365CKJKKBWQOU4S";
const SECRET_ACCESS_KEY = "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";

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

const ProductBasicInformation = ({
  productData,
  setProductDataHandler,
  mfrUnitList,
  setMfrUnitList,
}) => {
//   console.log(mfrUnitList);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async (file) => {
    uploadFile(file, config)
      .then((data) => console.log(data))
      .catch((err) => console.error(err));
  };

  const [units, setUnit] = useState([]);

  const unitList = units.map((unit) => (
    <option key={unit.id}>{unit.name}</option>
  ));

  useEffect(() => {
    (async () => {
      const mfr_id = localStorage.getItem("mfr_id");

      await axios
        .get(`/units/${mfr_id}`)
        .then((response) => {
          setUnit(response.data);
        })
        .catch((error) => console.log(error));
    })();
  }, []);

  const unitListHandler = (unit) => {
    setMfrUnitList((item) => [...item, unit]);
    // console.log("MFR unitlist",mfrUnitList);
   setTimeout(()=>{
       updateList();
   },1000)
  };
  
  const unitDeleteHandler = (unit) => {
    const updatedList = mfrUnitList.filter((item) => item !== unit);
    setMfrUnitList(updatedList);

    setTimeout(()=>{
        updateList();
    },1000)
  };

  const updateList = () =>{
    const aa = mfrUnitList.map(item => item).join(',');
    const event = {
        target: {
          name: "manufactured_by",
          value: aa,
        },
      };
    //   console.log("updateList", event);
    setProductDataHandler(event);
  }


  return (
    <div className="flex w-full mt-10">
      <div className="bg-white rounded-2lg mr-3 w-1/2 px-8 py-7 h-3/4">
        <div className="text-lg cursor-default font-poppins font-semibold text-gray-700 pb-4">
          Products Details
        </div>

        <div className="py-2">
          <p className="text-gray-600 pb-2 cursor-default">Product Name*</p>
          <input
            className="input-field"
            name="name"
            onChange={setProductDataHandler}
            value={productData.name}
            type="text"
          />
        </div>
        <div className="py-2">
          <p className="text-gray-600 cursor-default pb-2">Description*</p>
          <textarea
            className="textarea-field"
            name="description"
            value={productData.description}
            onChange={setProductDataHandler}
            type="text"
          />
        </div>

        <div className="flex space-x-3">
          <div className="py-2 w-full ">
            <p className="text-gray-600 pb-2 cursor-default">Product Weight*</p>
            <div className="relative flex flex-wrap items-stretch">
              <input
                className="input-field"
                type="number"
                name="weight"
                value={productData.weight}
                onChange={setProductDataHandler}
              />
              <select
                className="absolute inset-y-0 right-0 bg-gray-50 px-1 outline-none m-1"
                name="weight_unit"
                value={productData.weight_unit}
                onChange={setProductDataHandler}
              >
                <option>%</option>
                <option>Kcal</option>
                <option>Kg</option>
                <option>gm</option>
                <option>mg</option>
                <option>mcg</option>
                <option>Liter</option>
                <option>ml</option>
              </select>
            </div>
          </div>
        </div>
        <div className="flex space-x-2">
          <div className="py-2 w-full">
            <p className="text-gray-600 pb-2 cursor-default">SKU Number*</p>
            <input
              className="input-field"
              type="text"
              name="batch_number"
              value={productData.batch_number}
              onChange={setProductDataHandler}
            />
          </div>
        </div>
        <div className="flex space-x-2">
          <div className="py-2 w-full">
            <p className="text-gray-600 pb-2">Food preference</p>
            <select
              type="text"
              className="input-field"
              name="food_preference"
              value={productData.food_preference}
              onChange={setProductDataHandler}
            >
              <option>Vegetarian</option>
              <option>Non-Vegetarian</option>
            </select>
          </div>
        </div>
      </div>

      <div className="w-1/2 ">
        <div className="bg-white rounded-2lg ml-2 mb-2 px-8 py-7">
          <div className="text-lg font-poppins font-semibold cursor-default text-gray-700 pb-4">
            Products Images/Videos
          </div>
          <div className="py-2">
            <p className="text-gray-600 pb-2">URL</p>
            <input
              className="input-field"
              type="text"
              name="video_url"
              value={productData.video_url}
              onChange={setProductDataHandler}
            />
          </div>
          <div>
            {/* <ImagePicker  >
              <div className="m-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 ml-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                  />
                </svg>
                <p className="p-1">Upload</p>
              </div>
            </ImagePicker> */}

            <input type="file" onChange={handleFileInput} />
            <button onClick={() => handleUpload(selectedFile)}>
              {" "}
              Upload to S3
            </button>
          </div>
        </div>

        <div className="bg-white rounded-2lg ml-2 mt-5 px-8 py-6">
          <div className="text-lg font-poppins font-semibold text-gray-700 pb-4">
            Manufacturing Details
          </div>
          <div className="">
            <select
              type="text"
              className="input-field"
              name="unit"
              onChange={(event) => unitListHandler(event.target.value)}
            >
              {unitList}
            </select>
            <ul className="mx-8 py-4 my-4 rounded-md">
              {mfrUnitList
                ? mfrUnitList.map((item, id) => (
                    <li
                      className="flex px-4 py-2 my-2 font-poppins bg-gray-100 rounded-md"
                      key={id}
                    >
                      <div className="w-4/5">{item}</div>
                      <div
                        className="ml-16 p-1 rounded-md cursor-pointer hover:bg-gray-400"
                        onClick={() => unitDeleteHandler(item)}
                      >
                        <DeleteSVG />
                      </div>
                    </li>
                  ))
                : null}
            </ul>
          </div>
          <div className="py-2"></div>
        </div>
      </div>
    </div>
  );
};

export default ProductBasicInformation;
