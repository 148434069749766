import React from "react";  
import { Redirect, Route } from "react-router-dom";  


export const PrivateRoute = ({ component: Component, ...rest }) => {  
  
    return (  
    <Route  
      {...rest}  
      render={(props) => {  
       
        const isLoggedIn = localStorage.getItem('isLoggedIn');  

        return isLoggedIn === '1' ? (  
          <Component {...props} />  
        ) : (  
          <Redirect to={"/"} /> 
        );  
   }}  
   />  
  );  
};