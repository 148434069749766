import React, { useState } from "react";
import axios from "../../../../../axios/axios";

import { Edit, DargDrop, DeleteSVG, Check } from "../../../../../SVG/svg";

const AllergensRow = ({allergenInfo, flag, deleteItem}) => {

    const [edit, setEdit] = useState(false);
  const [bgColor, setBgColor] = useState(null);

  const [allergenItem, setAllergenItem] = useState({
    ...allergenInfo,
  });

  const updateItem = async () => {
      
      await axios
        .put(`/productAllergenInfo/`, allergenItem)
        .then((response) => {
         // console.log(response.data);
        // alert("Item Updated successfully");
        })
        .catch((error) => console.log(error));

    setBgColor(null);
    setEdit(false);
  };

  const editHandler = () => {
    setBgColor("white");
    setEdit(true);
  };

  const allergenInfoHandler = (event) => {
    setAllergenItem({
      ...allergenItem,
      [event.target.name]: event.target.value,
    });
  };

  return (
      <>
      <tr>
        <td className="pl-2 py-4 whitespace-nowrap">
          <DargDrop />
        </td>
        <td className="px-3 py-4 whitespace-nowrap">
          <div className="flex items-center">
            {/* <div className="flex-shrink-0 h-10 w-10">
              <img
                className="h-10 w-10 rounded-md"
                src={allergenInfo.image}
                alt=""
              />
            </div> */}
            <div className="ml-4">
              <div className="text-sm font-semibold text-gray-800">
              <input
              disabled={!edit}
              type="text"
              name="name"
              value={allergenItem.name}
              onChange={allergenInfoHandler}             
            className="appearance-none font-semibold ml-4 p-1 rounded-md bg-white"
            />
              </div>
            </div>
          </div>
        </td>
        <td className="px-3 py-4 whitespace-nowrap ">
          <div className=" text-sm  font-poppins text-gray-700  ">
            <input
              disabled={!edit}
              style={{backgroundColor: bgColor}}
              type="text"
              name="type_code"
              onChange={allergenInfoHandler}
              value={allergenItem.type_code}
              className="input-field text-sm font-poppins text-gray-700"
            />
          </div>
        </td>
        <td className="px-3 py-4 whitespace-nowrap text-sm font-poppins text-gray-700">
          <input
              disabled={!edit}
              style={{backgroundColor: bgColor}}
              type="text"
              name="contains"
              onChange={allergenInfoHandler}
              value={allergenItem.contains}
              className="input-field text-sm font-poppins text-gray-700"
            />
        </td>

        <td className="flex py-4 whitespace-nowrap">
        {edit ? (
            <button
              className="btn-default p-1"
              onClick={updateItem}
            >
              <Check/>
            </button>
          ) : (
            <div
              className="p-1 rounded-md cursor-pointer hover:bg-gray-200"
              onClick={editHandler}
            >
              <Edit />
            </div>
          )}
          <div className="p-1 rounded-md cursor-pointer hover:bg-gray-200" onClick={() => deleteItem(allergenInfo.id)}>
            <DeleteSVG />
          </div>
        </td>
      </tr>
      {flag ? (
        <tr>
          <td colSpan="1"></td>
          <td colSpan="2">
            No Allergens Information found of this props.product
          </td>
        </tr>
      ) : null}
    </>
  );
};

export default AllergensRow;
