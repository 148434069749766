import axios from "../../../../axios/axios";
import React, { useState, useEffect } from "react";
import { DeleteSVG, DownArrow, Edit, UpArrow } from "../../../../SVG/svg";
import VariantList from "./VariantList";

const Accordion = ({
  productInfo,
  addVariantHandler,
  viewProductHandler,
  viewVariantHandler,
  deleteHandler
}) => {
  const [isActive, setIsActive] = useState(false);
  const [variantList, setVariantList] = useState([]);

  let variantRow = null;

  useEffect(() => {
    (async () => {
      await axios
        .get(`/variant/${productInfo.product_basic_info[0].product_id}`)
        .then((response) => {
          setVariantList(response.data);
          // console.log("Variant List",variantList);
        })
        .catch((error) => console.log(error));
    })();
  }, [productInfo.product_basic_info]);

  if (variantList.length) {
    variantRow = variantList.map((variant, idx) => (
      <VariantList
        key={idx}
        variant={variant}
        setVariantList={setVariantList}
        viewVariantHandler={viewVariantHandler}
        code={productInfo.product_code}
        product_id={productInfo.product_basic_info[0].product_id}
      />
    ));
  } else {
    variantRow = (
      <tr>
        <td colSpan="7">
          <p className="bg-gray-50 py-2 pl-8 font-poppins">
            No Variants Found !
          </p>
        </td>
      </tr>
    );
  }

  return (
    <>
      <tr className="divide-y divide-gray-200">
        <td className="pl-9 py-4 whitespace-nowrap">
          <div>
            <input type="checkbox" className=" w-5 h-5" />
          </div>
        </td>
        <td className="pl-4 py-4 whitespace-nowrap">
          <div className="text-sm font-medium text-gray-500 cursor-default">
            {productInfo.product_code}
          </div>
        </td>
        <td className="pl-4 py-4 whitespace-nowrap">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              <img
                className="h-10 w-10 rounded-md"
                src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60"
                alt=""
              />
            </div>
            <div className="ml-4">
              <div
                className="text-sm font-medium text-blue-400 cursor-pointer hover:text-blue-500"
                onClick={() =>
                  viewProductHandler(
                    productInfo.product_basic_info[0].product_id
                  )
                }
              >
                {productInfo.product_basic_info[0].name} ({" "}
                {productInfo.product_basic_info[0].weight} {productInfo.product_basic_info[0].weight_unit}  )
              </div>
            </div>
          </div>
        </td>

        <td className="pl-4 py-4 whitespace-nowrap font-medium text-gray-600">
          {"123 / 123"}
        </td>        
        <td className="pl-4 py-4 whitespace-nowrap font-medium text-gray-600">
          <button
            className="btn-default px-2 py-1"
            onClick={() =>
              addVariantHandler(productInfo.product_basic_info[0].product_id)
            }
          >
            Add Variant
          </button>
        </td>
        <td colSpan="1" className="flex space-x-3 pt-3">
         <div
          className="p-2 rounded-md cursor-pointer hover:bg-gray-200"
          onClick={() =>
            viewProductHandler(
              productInfo.product_basic_info[0].product_id
            )
          }
          >
          <Edit/>
        </div>
         <span className="p-2 rounded-md cursor-pointer hover:bg-gray-200" onClick={() => deleteHandler(productInfo.product_basic_info[0].product_id)}>
           <DeleteSVG/>
          </span>
        </td>  
        <td colSpan="1" >
          <div
            onClick={() => setIsActive(!isActive)}
            className="hover:text-gray-600"
          >
            {isActive ? <UpArrow /> : <DownArrow />}
          </div>
        </td>
      </tr>
      {isActive && variantRow}
    </>
  );
};

export default Accordion;
