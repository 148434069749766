import axios from "../../../../axios/axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hooks-helper";
import { Add } from "../../../../SVG/svg";
import AllergensRow from "./TableRow/allergensRow";

const AllergensInformation = () => {
  const [allergenInfo, setAllergenInfo] = useState([]);

  const defaultProductData = {
    product_id: localStorage.getItem("current_product_id"),
    name: "",
    type_code: "",
    contains: "",
    variant_of:0,
  };

  const [allergenData, setAllergenData] = useForm(defaultProductData);

  useEffect(() => {
    (async () => {
      const id = localStorage.getItem("current_product_id");
      await axios
        .get(`/productAllergenInfo/${id}`)
        .then((response) => {
          setAllergenInfo(response.data);          
        }).catch( error => console.log(error));
    })();
  }, []);

  //   console.log(effectFun());
  const AddRow = async () => {
    if (allergenData.name && allergenData.type_code && allergenData.contains) {
      await axios
        .post(`/productAllergenInfo`, allergenData)
        .then(async (response) => {
          // setAllergenInfo(response.data);
          //  console.log("date...",productInfo);
          const id = localStorage.getItem("current_product_id");
          await axios
            .get(`/productAllergenInfo/${id}`)
            .then((response) => {
              setAllergenInfo(response.data);
            }).catch( error => console.log(error));
        }).catch( error => console.log(error));
    } else {
      alert("enter all field data");
    }
  };

  const updateItem = (id) => {
      console.log("update called",id)
  };

  const deleteItem = async(id) => {
    await axios
        .delete(`/productAllergenInfo/${id}`)
        .then(async (res) => {

          const product_id = localStorage.getItem("current_product_id");
          await axios
            .get(`/productAllergenInfo/${product_id}`)
            .then((response) => {
              setAllergenInfo(response.data);
            }).catch( error => console.log(error));
        }).catch( error => console.log(error));
  };


  return (
    <div className="mt-10 border-gray-200 rounded-3xl bg-white ">
      <div className="-my-2 overflow-x-auto ">
        <div className="py-2 align-middle inline-block min-w-full  ">
          <div className="overflow-hidden rounded-3xl">
            <div className="text-lg font-poppins font-semibold text-gray-700 py-4 px-6 ">
              Allergen Related Details
            </div>

            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-white">
                <tr>
                  <th></th>
                  <th scope="col" className="table-head">
                    Allergen Name
                  </th>
                  <th scope="col" className="table-head">
                    Allergen type code
                  </th>
                  <th scope="col" className="table-head">
                    Contains
                  </th>
                  <th scope="col" className="table-head">
                    {" "}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {allergenInfo.map((item) => (
                  <AllergensRow
                    key={item.id}
                    allergenInfo={item}
                    updateItem={updateItem}
                    deleteItem={deleteItem}                    
                  />
                ))}
                <tr key="1key">
                  <td> </td>
                  <td className="px-3 py-4 whitespace-nowrap">
                    <div className=" ">
                      <input
                        type="text"
                        name="name"
                        onChange={setAllergenData}
                        className="input-field text-sm font-poppins text-gray-700"
                      />
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap ">
                    <div className=" ">
                      <input
                        name="type_code"
                        onChange={setAllergenData}
                        type="text"
                        className="input-field text-sm font-poppins text-gray-700"
                      />
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm font-poppins text-gray-700">
                    <div className="">
                      <input
                        name="contains"
                        onChange={setAllergenData}
                        type="text"
                        className="input-field text-sm font-poppins text-gray-700"
                      />
                    </div>
                  </td>

                  <td className="px-3 py-4">
                    <button className="btn-default p-1" onClick={AddRow}>
                      <Add />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllergensInformation;
