/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://snkicpixjbbnjdyfnvqnltp6sy.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-mmqqgkf3wrg4tcheb77mndgjne",
    "aws_cognito_identity_pool_id": "ap-south-1:6b2b4faa-64ad-4e44-988e-b284de546299",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_XC3R8wJOK",
    "aws_user_pools_web_client_id": "2t9h5ti751778kdovov33k6uu1",
    "oauth": {}
};


export default awsmobile;
