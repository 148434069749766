import React, { useState } from "react";
import { Chat, Star, Whatsapp, StarFilled } from "../../../SVG/svg";
// import { Upload } from '../../../SVG/svg';

import Filter from "../../customElement/filter";
import Search from "../../customElement/search";
import Layout from "../layout/layout";

const ReviewManager = () => {
  const product = [
    {
      name: "Ramesh Kumar",
      review: "The product tastes awesome and packag...",
      rating: "4.5",
      date: "28-02-2021  12:24 pm",
      action: { 1: "Replay via text", 2: "Send whatsapp" },
      image:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
    },
    {
      name: "Vishnu Vardhan",
      review: "The product tastes awesome and packag...",
      rating: "4.5",
      date: "28-02-2021  12:24 pm",
      action: { 1: "Replay via text", 2: "Send whatsapp" },
      image:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
    },
    {
      name: "Suriya Deva",
      review: "The product tastes awesome and packag...",
      rating: "4.5",
      date: "28-02-2021  12:24 pm",
      action: { 1: "Replay via text", 2: "Send whatsapp" },
      image:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
    },
  ];

  const ProgressBar = [
      { id: 5, value: "40" },
      { id: 4, value: "60" },
      { id: 3, value: "20" },
      { id: 2, value: "10" },
      { id: 1, value: "5" },
    ];

  const userReview = (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-white">
        <tr>
          <th scope="col" className="table-head">
            Name
          </th>
          <th scope="col" className="table-head">
            Review
          </th>
          <th scope="col" className="table-head">
            Rating
          </th>
          <th scope="col" className="table-head">
            Date
          </th>
          <th scope="col" className="table-head">
            Action
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {product.map((product) => (
          <tr key={product.name}>
            <td className="pl-5 py-4 whitespace-nowrap">
              <div className="flex items-center">
                <div className="flex-shrink-0 h-10 w-10">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={product.image}
                    alt=""
                  />
                </div>
                <div className="ml-4">
                  <div className="text-sm font-medium text-gray-600">
                    {product.name}
                  </div>
                </div>
              </div>
            </td>
            <td className="pl-5 py-4 whitespace-nowrap">
              <div className="text-sm font-medium  text-gray-600">
                {product.review}
              </div>
            </td>
            <td className="flex space-x-2 pl-5 pt-5 font-medium whitespace-nowrap text-gray-600">
              <span>
                <Star />
              </span>{" "}
              <p>{product.rating}/5</p>
            </td>
            <td className="pl-5 py-4 whitespace-nowrap text-gray-600">
              {product.date}
            </td>
            <td className="pl-5 py-4 whitespace-nowrap font-medium text-blue-500">
              <div className="flex space-x-2">
                <div className="flex space-x-2">
                  <span className="pt-1 pr-1">
                    <Chat />
                  </span>
                  {product.action[1]}
                </div>
                <div className="flex space-x-2">
                  <span className="pt-1 pr-1">
                    {" "}
                    <Whatsapp />{" "}
                  </span>
                  {product.action[2]}{" "}
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const productReview = (
    <div className=" px-10 pt-6 pb-10 bg-white ">
      <div className="flex space-x-4">
        <img
          className="rounded-3xl w-32 h-32"
          alt="img"
          src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60"
        />

        <div className="flex-col space-y-1 pt-6 px-2">
          <h1 className="font-semibold text-lg text-gray-500">Product Code</h1>
          <p className=" font-poppins text-lg text-gray-800">ABCD123</p>{" "}
        </div>
        <div className="flex-col space-y-1 pt-6 px-2">
          <h1 className="font-semibold text-lg text-gray-500">Product Name</h1>
          <p className=" font-semibold text-lg">Peanut Butter</p>{" "}
        </div>
      </div>
      <div className="font-semibold text-lg text-gray-500 pt-6">
        Ratings & Reviews
      </div>
      <div className="flex-col divide-y divide-gray-200  ">
        <div className="flex space-x-10 py-2">
          <div className="flex-col space-y-1 pl-2">
            <div className="flex space-x-1 text-gray-800 font-poppins font-semibold text-3xl py-1">
              <div className="px-1 pl-2">
                <StarFilled styles="w-12 h-12" />
              </div>
              <div className="pt-1">4.5</div>
            </div>
            <div className="text-xs px-4 py-1 text-gray-500 font-poppins">
              {120} Ratings & <br /> {12} Reviews
            </div>
          </div>
          <div className="flex-col">
            {ProgressBar.map( op => (
              <div className="flex space-x-2 text-xs text-center text-gray-800 " key={op.id} >
                <div className="flex space-x-1" >                  
                  <StarFilled styles="w-4 h-4"/>
                  <p>{op.id}</p> 
                </div>
                <div className="relative">
                  <div className="overflow-hidden w-80 h-2 mb-4 text-xs flex rounded bg-gray-200">
                    <div                      
                      className={ "w-"+op.value +" shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"}
                    >
                    </div>
                  </div>
                </div>
                <p>{op.value} </p>
              </div>
            ))}
          </div>
          <div className="flex space-x-4 pl-12" >
              <img alt="img1" className="w-28 h-28 rounded-2xl" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60"/>
              <img alt="img1" className="w-28 h-28 rounded-2xl" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60"/>
              <img alt="img1" className="w-28 h-28 rounded-2xl" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60"/>
              <img alt="img1" className="w-28 h-28 rounded-2xl" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60"/>
          </div>
        </div>
        <div>reviews ....... </div>
      </div>
    </div>
  );

  const [reviewFlag, setReview] = useState(true);

  const reviewHandler = (e) => {
    if (e) {
      console.log("true....");
      setReview(e);
    } else {
      console.log("false....");
      setReview(e);
    }
  };

  return (
    <Layout>
      <div className="flex">
        <div className="heading">Reviews Management</div>
      </div>
      <div className="flex flex-col mt-14">
        <div className="-my-2 overflow-x-auto ">
          <div className="py-2 align-middle inline-block w-full  ">
            <div className="shadow overflow-hidden border-b border-gray-200 rounded-layout ">
              <div className="bg-white flex-col w-full">
                <div className="flex">
                  <div className="flex-grow">
                    <Search />
                  </div>
                  <div className="">
                    <Filter />
                  </div>
                  <div className="w-6"></div>
                </div>
                <div className="flex text-xl space-x-3 font-semibold text-gray-600 px-2 mb-2 ml-8">
                  <h1
                    className=" hover:underline hover:text-blue-400 cursor-pointer"
                    onClick={(e) => reviewHandler((e = true))}
                  >
                    User Review
                  </h1>
                  <h1
                    className="hover:underline hover:text-blue-400 cursor-pointer"
                    onClick={(e) => reviewHandler((e = false))}
                  >
                    Product Review
                  </h1>
                </div>
                <div className="w-full h-1 text-gray-500">
                  <hr />{" "}
                </div>
              </div>
              {reviewFlag ? userReview : productReview}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ReviewManager;
