const Step4 = ({ signUpData, navigation, setSignUpData }) => {
  return (
    <div className="mt-20 w-input">
      <div className=" ">
      <p className="text-gray-600 font-poppins cursor-pointer hover:text-gray-900 py-2" onClick={() => navigation.go("SignUpStep2")} >{"< Back"}</p>
        <p className="text-black text-4xl w-full font-poppins px-8 justify-items-center mb-8">
            Welcome to <strong>Stegofy</strong> 👋
        </p>
        <p className="py-3 pb-2">
          Please fill the details of your company below to get one step closer
          to secure the authenticity of your products.
        </p>
        <input
          type="text"
          className="input"
          name="brandName"
          onChange={setSignUpData}
          placeholder="Company name"
        />
        <textarea
          rows="4"
          cols="52"
          className="textarea "
          name="address"
          onChange={setSignUpData}
          placeholder="Company Address"
        />

        <input
          type="text"
          className="input"
          name="city"
          onChange={setSignUpData}
          placeholder="City"
        />

        <div className="flex w-full space-x-3">
          <select
            type="text"
            className="input w-1/2"
            name="state"
            onChange={setSignUpData}
            placeholder="State"
          >
            <option>State 1</option>
            <option>State 2</option>
            <option>State 3</option>
          </select>
          <input
            type="text"
            className="input w-1/2"
            name="pinCode"
            onChange={setSignUpData}
            placeholder="Pin code"
          />
        </div>
        <div className="relative flex items-stretch" >
        <span className="absolute inset-y-0 left-0 text-gray-600 text-lg font-normal text-center bg-transparent items-center justify-center w-10 h-10 pt-4 pl-2">
                  +91<span className="px-1">|</span>
                  </span>    
        <input 
         style={{paddingLeft:'50px'}}
          type="number"
          className="input"
          name="supportNumber"
          onChange={setSignUpData}
          placeholder="Contact number"
        />
        </div>    
        <button className="btn btn-blue" onClick={() => navigation.next()}>
          next
        </button>
      </div>
    </div>
  );
};

export default Step4;
