import React, { useContext, useState,useRef,useEffect } from "react";
import AuthContext from "../../../context-store/auth-context";
import { Eye, EyeOff } from "../../../SVG/svg";
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import validator from 'validator'
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import axios from 'axios';


 

const SignUpStep1 = ({ navigation, signUpPhoneHandler }) => {
  const authCtx = useContext(AuthContext);
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [type1, setType1] = useState("password");
  const [eye1, setEye1] = useState(true);
  const otpVal = useRef(null);
  const [nameerror, setnameerror] = useState(false)
  const [emailerror, setemailerror] = useState(false)
  const [nameInput, setnameInput] = useState('')
  const [emailInput, setemailInput] = useState('')
  const [phoneInput, setphoneInput] = useState('')
  const [phoneerror, setphoneerror] = useState(false)
  const [pswerror, setpswerror] = useState(false)
  const [phoneOtp, setphoneOtp] = useState('')
  const [host, sethost]= useState('')
  // http://localhost:5000
  const [statuspop, setstatuspop] = useState({
    open: false,
    text: "",
    color: "",
  });
 
  const setTypeHandler1 = () => {
    if (type1 === "password") {
      setType1("text");
      setEye1(false);
    } else {
      setType1("password");
      setEye1(true);
    }
  };

  const [type, setType] = useState("password");
  const [eye, setEye] = useState(true);

  const [toSend, setToSend] = useState({
    to_name: '',
    otp:otpVal.current,
    reply_to: '',
  });
 


  const setTypeHandler = () => {
    if (type === "password") {
      setType("text");
      setEye(false);
    } else {
      setType("password");
      setEye(true);
    }
  };



  const passwordHandler = (e) => {
    let temppsw=e.target.value;
    setPassword(temppsw);

    // const passwordHashed = CryptoJS.AES.encrypt(
    //     password,
    //     "Zz123!@#$Rcbt48k"
    //   ).toString(CryptoJS.enc.Utf8);

    authCtx.password = e.target.value;

  };

  const rePasswordHandler = (e) => {
    const temprepsw=e.target.value;
    setRePassword(temprepsw);
  if(temprepsw===password){
setpswerror(false);
  }
  else{
    setpswerror(true);
  }
  
  }
  

  const nameHandler = (e) => {
    const tempname=e.target.value;
   setnameInput(tempname);

     let result = tempname.match(/[0-9]/g);
    if(Boolean(result)) {
      setnameerror(true);
      console.log('error');
     
    }
    else if(tempname==""){
      setnameerror(null);
    }
    else{
      setnameerror(false);
      console.log('no error');
    }
    setToSend({
      ...toSend,
      to_name: e.target.value,
    })
    authCtx.userName = e.target.value;
  };

  
  const emailHandler = (e) => {
    const tempemail=e.target.value;
    setemailInput(tempemail);

    if(validator.isEmail(tempemail)){
      setemailerror(false);
    }
    else{
      setemailerror(true);
    }
    setToSend({
      ...toSend,
      reply_to: e.target.value,
    })
    authCtx.email = e.target.value;
  };


  const phoneHandler = (e) => {
    const tempphoneno=e.target.value;

    setphoneInput(tempphoneno);
    if(tempphoneno.length===10){
      setphoneerror(false);
    }
    else{
      setphoneerror(true);
    }
    
    authCtx.phone_number= e.target.value;
  };


//   useEffect(() => {

//     var digits = '0123456789';
//     var OTP = '';
//     for (let i = 0; i < 6; i++ ) {
//         OTP += digits[Math.floor(Math.random() * 10)];
//     }
//     otpVal.current=OTP;
//     authCtx.authemailOtp=OTP;

// setToSend({
//   ...toSend,
//   otp: otpVal.current,
// })
//   }, []);


  useEffect(() => {

    var digits = '0123456789';
    var OTP = '';
    for (let i = 0; i < 6; i++ ) {
        OTP += digits[Math.floor(Math.random() * 10)];
    }
    otpVal.current=OTP;
    authCtx.authmobOtp=OTP;
    
  }, []);
  
  const continueHandler =  (e) => {
   
    if(nameerror==false && Boolean(nameInput) && emailerror==false &&Boolean(emailInput) && phoneerror==false && Boolean(phoneInput)&& pswerror==false && Boolean(password) && Boolean(rePassword)){    
//  axios.post(`${host}/api/sendMail`,toSend)
navigation.go("SignUpStep2");
  axios
    .get(`https://stegofy-heroku-001.herokuapp.com/api/sendOtp/${phoneInput}/${authCtx.authmobOtp}`)
    .then((response) => {
      console.log(response.data);
    }).catch( error => console.log(error));
 
    

    setstatuspop({
      color: "success",
      open: true,
      text: "SignUp successfully",
    });

    }
    else{
      
      setstatuspop({
        color: "error",
        open: true,
        text: "SignUp failed",
      });
    }
    
  
  };

  return (
    <div className="mt-32 sm:mt-20 mob:mt-12 w-input mob:w-auto md:w-auto sm:text-center md:text-center mob:text-center">
      <div className="sm:ml-auto sm:mr-auto">
        <p className="text-black md:text-4xl xl:text-4xl 2xl:text-4xl lg:text-4xl sm:text-3xl w-full font-poppins px-8 justify-items-center mb-8 mob:text-2xl">
          Welcome to <strong>Stegofy</strong> 👋
        </p>
        {statuspop && (
            <div>
              <Snackbar
                open={statuspop.open}
                TransitionComponent="SlideTransition"
                autoHideDuration={6000}
                onClose={() => {
                  setstatuspop({
                    ...statuspop,
                    open: false,
                  });
                }}
              >
                <Alert
                  onClose={() => {
                    setstatuspop({
                      ...statuspop,
                      open: false,
                    });
                  }}
                  severity={statuspop.color}
                  sx={{ width: "100%" }}
                >
                  {statuspop.text}
                </Alert>
              </Snackbar>
            </div>
          )}

        <div className="relative flex w-full flex-wrap items-stretch mb-3 mob:justify-center sm:justify-center md:justify-center">

<div className="relative flex w-full flex-row mb-3 mob:justify-center sm:justify-center md:justify-center">
 
          <input
            type="text"
            onChange={nameHandler}
            className={nameerror&&Boolean(nameInput) ?"input sm:w-3/4 md:w-3/4 mob:w-4/5 mob:text-base border-red-300 hover:border-red-300":Boolean(nameInput)?"input sm:w-3/4 md:w-3/4 mob:w-4/5 mob:text-base border-green-200 hover:border-green-200 focus:border-green-200 ":"input sm:w-3/4 md:w-3/4 mob:w-4/5 mob:text-base border-grey-500 hover:border-grey-500 focus:border-indigo-300 "}   
           
            name="name"
            value={nameInput}
          
           
            placeholder="Enter your Name"
          />
          
          {/* <div>
 {nameerror&&Boolean(nameInput) ? <ErrorIcon sx={{color:'red',marginTop:'15px',fontSize:'30px'}}/> :<CheckCircleIcon sx={{color:'green',marginTop:'15px',fontSize:'24px'}}/>}
 </div> */}
 </div>


 <div className="relative flex w-full flex-row   mb-3 mob:justify-center sm:justify-center md:justify-center">
 <input
            type="text"
            className={emailerror&&Boolean(emailInput) ?"input sm:w-3/4 md:w-3/4 mob:w-4/5 mob:text-base border-red-400 hover:border-red-400":Boolean(emailInput)?"input sm:w-3/4 md:w-3/4 mob:w-4/5 mob:text-base border-green-300 hover:border-green-200 focus:border-green-200 ":"input sm:w-3/4 md:w-3/4 mob:w-4/5 mob:text-base border-grey-500 hover:border-grey-500 focus:border-indigo-300 "} 
            name="email"
            onChange={emailHandler}
            placeholder="Enter Email"
          />
          {/* {emailerror ? <ErrorIcon sx={{color:'red',marginTop:'15px',fontSize:'30px'}}/> :''} */}

 </div>
         
          <div className="relative flex items-stretch w-full sm:justify-center mob:justify-center md:justify-center">
            <span className="absolute inset-y-0 left-0 text-gray-600 text-lg font-normal text-center bg-transparent items-center justify-center w-10 h-10 pt-4 md:pl-7 mob:pl-6 sm:ml-14 sm:pl-3 md:ml-16 mob:ml-6 mob:text-base">
              +91<span className="px-1 mob:px-0">|</span>
            </span>
            <input
              style={{ paddingLeft: "47px" }}
              type="number"
              className={phoneerror&&Boolean(phoneInput) ?" input sm:w-3/4 md:w-3/4 mob:w-4/5 mob:text-base border-red-400 hover:border-red-400 ":Boolean(phoneInput)?" input sm:w-3/4 md:w-3/4 mob:w-4/5 mob:text-base border-green-500 hover:border-green-300 focus:border-green-300":"input sm:w-3/4 md:w-3/4 mob:w-4/5 mob:text-base border-grey-500 hover:border-grey-500 focus:border-indigo-300"}    
              name="phone_number"
              onChange={phoneHandler}
              placeholder="Enter Mobile No."
            />
          </div>
          <div className="relative flex w-full flex-wrap items-stretch mb-3 sm:justify-center md:justify-center mob:justify-center">
        <input
          type={type}
          name="password"
          onChange={passwordHandler}
          placeholder="Enter Password"
          className={pswerror ?'input sm:w-3/4 md:w-3/4 mob:w-4/5 mob:text-base':Boolean(password)?'input sm:w-3/4 md:w-3/4 mob:w-4/5 mob:text-base border-green-300 hover:border-green-300 focus:border-green-300 ':'input sm:w-3/4 md:w-3/4 mob:w-4/5 mob:text-base border-grey-500 hover:border-grey-500 focus:border-indigo-300'}
        />
        <span
          onClick={setTypeHandler}
          className="absolute inset-y-0 md:right-17 md:pr-11 right-4 sm:right-14 md:right-14 text-gray-600 h-full font-normal text-center items-center justify-center mob:mr-6  mob:pl-9 py-5 px-2 mob:text-base "
        >
          {eye ? <Eye /> : <EyeOff/> }
        </span>
      </div>
      <div className="relative flex w-full flex-wrap items-stretch mb-3 sm:justify-center md:justify-center mob:justify-center">
        <input
          type={type1}
          name="rePassword"
          onChange={rePasswordHandler}
        
          placeholder="Re-Enter Password"
          className={pswerror&&Boolean(rePassword) ?" input sm:w-3/4 md:w-3/4 mob:w-4/5 mob:text-base border-red-400 hover:border-red-400":Boolean(rePassword)?" input sm:w-3/4 md:w-3/4 mob:w-4/5 mob:text-base border-green-300 hover:border-green-300 focus:border-green-300":"input sm:w-3/4 md:w-3/4 mob:w-4/5 mob:text-base border-grey-500 hover:border-grey-500 focus:border-indigo-300 "}    
              
        />
        <span
          onClick={setTypeHandler1}
          className="absolute inset-y-0 z-20 md:right-17 md:pr-11 sm:right-14 right-4 md:right-14 text-gray-600 h-full font-normal text-center items-center  justify-center mob:mr-6 mob:pl-9 py-5 px-2 mob:text-base"
        >
          {eye1 ? <Eye /> : <EyeOff/> }
        </span>
      </div>
    
        </div>

        <button className="btn btn-blue sm:w-3/4 md:w-3/4 md:justify-center mob:w-3/4" onClick={continueHandler}>
          Continue
        </button>

        <p className="font-sans h-24 text-lg text-black p-4 text-center">
        {"Existing User? "}
        <button
          className="text-blue-600 font-semibold hover:text-blue-900 focus:outline-none"
          onClick={() => navigation.go("step1")}
        >
          {"Log In"}
        </button>
      </p>
      </div>
    </div>
  );
};

export default SignUpStep1;
