import axios from 'axios';

const instance = axios.create({
     baseURL: 'https://stegofy-heroku-001.herokuapp.com'
     //baseURL: 'http://localhost:5000/'
});

// console.log("base url", baseUrl);

instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';

// instance.interceptors.request...

export default instance;
