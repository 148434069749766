import { useStep, useForm } from "react-hooks-helper";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import axios from '../../axios/axios';

import SideLogo from "./sideLogo/sideLogo";
import Step1 from "./formSteps/Step1";
import Step2 from "./formSteps/Step2";
import Step4 from "./formSteps/Step4";
import Step5 from "./formSteps/Step5";

import SignUpStep2 from "./formSteps/SignUpStep2";
import SignUpStep1 from "./formSteps/SignUpStep1";
import AuthContext from "../../context-store/auth-context";

const defaultSignUpData = {
  brandName: " ",
  address: " ",
  city: " ",
  state: " ",
  pinCode: " ",
  supportNumber: " ",
  compLogo: " ",
  businessType: " ",
  industry: " ",
  fssaiNumber: " ",
  panNumber: " ",
  gstNumber: " ",
};

const steps = [
  { id: "step1" },
  { id: "step2" },
  { id: "SignUpStep1" },
  { id: "SignUpStep2" },
  { id: "step4" },
  { id: "step5" },
];

const Login = () => {
  const history = useHistory();
  const authCtx = useContext(AuthContext);

  const [signUpData, setSignUpData] = useForm(defaultSignUpData);
 
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const submitHandler = async () => {

    const mfr_id = authCtx.user_id;
    await axios
    .put(`/afterSignup/${mfr_id}`,)
    .then((response) => {
      console.log("signup data updated", response.status);
      console.log(response.data);
      if(response.status === 200){
        localStorage.setItem('mfr_id', mfr_id);
      }      
  });

    // submit handler of step 3
    await axios
      .post("/profile", {
        ...signUpData,
        mfr_user_id: authCtx.user_id,
      })
      .then((response) => {
        console.log("successfully Profile data inserted", response.data);
      });
      // to make private route access able
     authCtx.onLogin(); 
    history.push("/wait");
  };

  const props = {
    signUpData,
    step,
    navigation,
    setSignUpData,
    submitHandler,
    };

  let StepRender = <Step1 {...props} />;

  switch (step.id) {
    case "step1":
      StepRender = <Step1 {...props} />;
      break;
    case "step2":
      StepRender = <Step2 {...props} />;
      break;
    
    case "step4":
      StepRender = <Step4 {...props} />;
      break;
    case "step5":
      StepRender = <Step5 {...props} />;
      break;
    case "SignUpStep1":
      StepRender = <SignUpStep1 {...props} />;
      break;
    case "SignUpStep2":
      StepRender = <SignUpStep2 {...props} />;
      break;

    default:
      break;
  }

  return (
    <>
      <div className="flex  min-h-screen mob:h-full sm:flex-col mob:flex-col ">
        <SideLogo />
        <div className='ml-auto mr-auto mob:h-auto sm:h-auto'>{StepRender}</div>
      </div>
    </>
  );
};

export default Login;
