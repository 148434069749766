import ProfilePage from "./profile/profile";
import Product from "./products/index";
import SerializedQR from "./serializedQR/serializedQR";
import Analytics from "./analytics/analytics";
import Campaign from "./campaign/campaign";
import Customers from "./customers/customers";
import Dashbord from "./dashbord/dashbord";
import ReviewManager from "./reviewManager/reviewManager";
import SupportWarranty from "./supportWarranty/supportWarranty";
import TrackTrace from "./trackTrace/trackTrace";
import Loyalty from "./loyalty/loyalty";

export const dashbord = () => {
    return <Dashbord />;
};

export const profile = () => {
  return <ProfilePage />;
};

export const products = () => {
  return <Product />;
};

export const serializedQR = () => {
  return <SerializedQR />;
};

export const analytics = () => {
  return <Analytics />;
};

export const campaign = () => {
  return <Campaign />;
};
export const supportWarranty = () => {
  return <SupportWarranty />;
};

export const trackTrace = () => {
  return <TrackTrace />;
};

export const customers = () => {
  return <Customers />;
};

export const reviewManager = () => {
  return <ReviewManager />;
};
export const loyalty = () => {
    return <Loyalty />;
  };
