import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import AuthContext from "../../../context-store/auth-context";
import { Bell } from "../../../SVG/svg";

const ProfileLogo = () => {
  const [drop, setDrop] = useState(false);
  const [drop2, setDrop2] = useState(false);

  const authCtx = useContext(AuthContext);

  const logoutHandler = () =>{    
      localStorage.clear();
    authCtx.onLogout();        
}



  const DropDown = (
    <div
      className="absolute right-0 mt-16 mr-4 bg-white rounded-md shadow-lg overflow-hidden z-20"
      style={{ width: "20rem" }}
    >
      <div className="py-2">
        <a
          href="/"
          className="flex items-center px-4 py-3 hover:bg-gray-100 -mx-2"
        >
          <img
            className="h-8 w-8 rounded-full object-cover mx-1"
            src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=398&q=80"
            alt="avatar"
          />
          <p className="text-gray-600 text-sm mx-2">
            <span className="font-bold" href="#">
              Abigail Bennett
            </span>{" "}
            start following you . 3h
          </p>
        </a>
        <a href="/" className="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2">
                    <img className="h-8 w-8 rounded-full object-cover mx-1" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80" alt="avatar" />
                    <p className="text-gray-600 text-sm mx-2">
                        <span className="font-bold" href="#">Sara Salah</span> replied on the <span className="font-bold text-blue-500" href="#">Upload Image</span> artical . 2m
                    </p>
                </a>
                <a href="/" className="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2">
                    <img className="h-8 w-8 rounded-full object-cover mx-1" src="https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80" alt="avatar" />
                    <p className="text-gray-600 text-sm mx-2">
                        <span className="font-bold" href="#">Slick Net</span> start following you . 45m
                    </p>
                </a>
                <a href="/" className="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2">
                    <img className="h-8 w-8 rounded-full object-cover mx-1" src="https://images.unsplash.com/photo-1450297350677-623de575f31c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80" alt="avatar" />
                    <p className="text-gray-600 text-sm mx-2">
                        <span className="font-bold" href="#">Jane Doe</span> Like Your reply on <span className="font-bold text-blue-500" href="#">Test with TDD</span> artical . 1h
                    </p>
                </a>
      </div>
      <a
        href="/"
        className="block bg-gray-800 text-white text-center font-bold py-2"
      >
        See all notifications
      </a>
    </div>
  );

  const DropDown2 = (
    <div
      className="absolute right-0 mt-16 mr-4 bg-white rounded-md shadow-lg overflow-hidden z-20"
      style={{ width: "20rem" }}
    >
      <div className="py-2">       
          <div className="text-gray-600 text-sm p-4 mx-2">
            <div className="font-bold py-1">
              User Name: {authCtx.userName}
            </div> 
             <div className="text-gray-500">email: {authCtx.email}</div>                     
          </div> 
      </div>
      <a
        href="/"
        className="block bg-gray-800 text-white text-center font-bold py-2"
        onClick={logoutHandler}
      >
          Log Out
      </a>
    </div>
  );

  return (
    <>
      <div className="flex space-x-1 w-56 h-12 rounded-md bg-white">
        <NavLink to="/profile" className="flex cursor-pointer">
          <img
            className="h-full w-12 rounded-md"
            src="/profile/profile-photo.png"
            alt="logo"
          />
          <p className="text-sm py-3 px-2 font-medium text-gray-700 hover:text-gray-900">
            Store Name
          </p>
        </NavLink>
        <div
          className=" cursor-pointer text-gray-700 pt-2 m-1 px-2 rounded-md hover:bg-gray-100 hover:rounded-full"
          onClick={() => (drop ? setDrop(false) : setDrop(true))}
        >
          <Bell />
        </div>
        <div className="flex px-2 m-1 text-center rounded-md text-base font-semibold text-gray-700 cursor-pointer hover:bg-gray-100 hover:px-2" onClick={() => (drop2 ? setDrop2(false) : setDrop2(true))}>
          <div>.</div>
          <div>.</div>
          <div>.</div>
        </div>
      </div>
      {drop ? DropDown : ""}
      {drop2 ? DropDown2 : ""}
    </>
  );
};

export default ProfileLogo;
