import React, { useEffect, useState } from "react";
import { useStep} from "react-hooks-helper";
import axios from "../../../axios/axios";

import AllergensInformation from "./addProduct/AllergensInformation";
import Ingredients from "./addProduct/Ingredients";
import ProductBasicInformation from "./addProduct/ProductBasicInformation";
import CookingInformation from "./addProduct/CookingInformation";
import RecyclingInformation from "./addProduct/RecyclingInformation";
import NutritionalInformation from "./addProduct/NutritionalInformation";
import ProductSuggestions from "./addProduct/ProductSuggestions";

const AddProduct = ({view, backClick }) => {
  const steps = [
    { id: "AllergensInformation" },
    { id: "ProductBasicInformation" },
    { id: "Ingredients"},
    { id: "NutritionalInformation" },
    { id: "RecyclingInformation" },
    { id: "CookingInformation" },
    { id: "ProductSuggestions" },
  ];

  const { step, navigation } = useStep({
    steps,
    initialStep: 1,
  });

  const [saveType, setSaveType] = useState("ProductBasicInformation");
  
  const onSelectHandler = (e) => {
    navigation.go(e.target.value);
    setSaveType(e.target.value);
  };

  const aa = new Date();
  const product_code = "CODE" + aa.getTime();

  const defaultProductData = {    
    name: "",
    description: "",
    price: "",
    weight: "",
    weight_unit:"mg",
    batch_number: "",
    consume_within: "",
    expiry_date: "",
    food_preference: "Vegetarian",
    video_url: "",
    image_name: "",
    manufactured_by: "",
    location: "",
    manufactured_date: "",
  };

  const defaultRecycleInfo = {
    recycling_type: "",
    how_to_recycle: "",
    recyclable: false,
    recyclable_times: 2,
  };

  const defaultSuggestionInfo = {
    serve_with: "",
    suggest_product: "",
    used_with: "",
    
  };

  const [productData, setProductData] = useState(defaultProductData);
  const [recycleInfo, setRecycleInfo] = useState(defaultRecycleInfo);
  const [suggestionInfo, setSuggestionInfo] = useState(defaultSuggestionInfo);
  
  const [mfrUnitList, setMfrUnitList] = useState([]);

  const setSuggestionHandler = (event) => {
    setSuggestionInfo({
      ...suggestionInfo,
      product_id: localStorage.getItem("current_product_id"),
      [event.target.name]: event.target.value,
    });
  };

  
  const setProductDataHandler = (event) => {
      //const unitLists = mfrUnitList.map(item => item).join(',');
      
      setProductData({
          ...productData,
          //manufactured_by: unitLists,
          [event.target.name]: event.target.value
        });

        // console.log(productData);
    };
    
  const setRecycleInfoHandler = (event) => {
    setRecycleInfo({
      ...recycleInfo,
      product_id: localStorage.getItem("current_product_id"),
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
        if(view){
      const product_id = localStorage.getItem("current_product_id");
      //console.log("variant product_id",product_id);
      (async () => {
          await axios
          .get(`/productInfo/parent/${product_id}`)
          .then((response) => {
              setProductData({
              ...response.data[0]                            
            });          
            const aa = response.data[0].manufactured_by.split(',');
            // console.log(aa);
            setMfrUnitList(aa);

        }).catch( error => console.log(error));

        await axios
          .get(`/recycleInfo/${product_id}`)
          .then((response) => {
              setRecycleInfo({
              ...response.data[0]              
            });          
        }).catch( error => console.log(error));

        await axios
          .get(`/productSuggestionsInfo/${product_id}`)
          .then((response) => {
              setSuggestionInfo({
              ...response.data[0]              
            });          
        }).catch( error => console.log(error));

    })();
}
}, [view]);

const [product, setProduct] = useState(defaultProductData);

const props1 = {
  productData,
  setProductDataHandler,
  product,
  setProduct,
  recycleInfo,
  setRecycleInfoHandler,
  suggestionInfo,
  setSuggestionHandler,
  view,
  setMfrUnitList,
  mfrUnitList    
};

let StepRender = <ProductBasicInformation {...props1} />;

  const saveHandler = async () => {

    // add main product logic
    if (saveType === "ProductBasicInformation") {
      const products = {
        mfr_id: +localStorage.getItem("mfr_id"),
        product_code: product_code,
      };
     if(view){
        await axios
              .put("/productInfo", productData)
              .then((response) => {
                 alert("Product Basics Information Updated");                 
              }).catch( error => console.log(error));
     } else{
         await axios
           .post("/products", products)
           .then(async (response) => {
             const productInfo = { ...productData, product_id: response.data.id };
             localStorage.setItem("current_product_id", response.data.id);
   
             await axios
               .post("/productInfo", productInfo)
               .then((response) => {
               });
             alert("Product Details saved");
             StepRender = <AllergensInformation {...props1} />;
           }).catch( error => console.log(error));
     }

    }
    if (saveType === "RecyclingInformation") {
        if(view){
            await axios
              .put("/productRecycleInfo", recycleInfo)
              .then((response) => {
                 alert("Product Recycling Information Updated");                 
              }).catch( error => console.log(error));
        }else{
            await axios
              .post("/productRecycleInfo", recycleInfo)
              .then((response) => {
                 alert("Product RecyclingInformation saved");
              }).catch( error => console.log(error));
        }
    }
    if (saveType === "ProductSuggestions") {

        if(view){
            await axios
            .put(
              "/productSuggestionsInfo",
              suggestionInfo
            )
            .then((response) => {
                alert("Product Suggestions Updated");
            }).catch( error => console.log(error));

        }else{
            await axios
              .post(
                "/productSuggestionsInfo",
                suggestionInfo
              )
              .then((response) => {
                  alert("Product Suggestions info saved");
              }).catch( error => console.log(error));            
        }
    }

  };


  switch (step.id) {
    case "ProductBasicInformation":
      StepRender = <ProductBasicInformation {...props1} />;
      break;
    case "Ingredients":
        StepRender = <Ingredients {...props1} />;
        break;  
    case "AllergensInformation":
      StepRender = <AllergensInformation {...props1} />;
      break;
    case "NutritionalInformation":
      StepRender = <NutritionalInformation {...props1} />;
      break;
    case "RecyclingInformation":
      StepRender = <RecyclingInformation {...props1} />;
      break;
    case "CookingInformation":
      StepRender = <CookingInformation {...props1} />;
      break;
    case "ProductSuggestions":
      StepRender = <ProductSuggestions {...props1} />;
      break;
    default:
      break;
  }

  return (
    <div>
      <p onClick={backClick} className="cursor-pointer">
        {" "}
        {" < Back "}{" "}
      </p>
      <div className="flex px-2 py-4">
        <div className="heading">{view ? "Update Product Details" : "Add New Product"}</div>
        <div className="flex-grow"></div>
        <button className="flex btn-default px-6 py-2 w-36 mr-3">
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>{" "}
          <p className="px-2" style={{ paddingTop: "1px" }}>
            Restart
          </p>
        </button>

        <div>
          <button
            className="flex btn-default px-8 py-2 w-36"
            onClick={saveHandler}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.3}
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <p className="px-2" style={{ paddingTop: "1px" }}>
              Save
            </p>
          </button>
        </div>
      </div>
      <div>
        <select
          type="text"
          className=" text-base text-white font-poppins rounded-lg px-6 py-2 focus:border-none focus:outline-none bg-indigo-stegofy"
          onChange={(e) => {
            onSelectHandler(e);
          }}
        >
          <option
            className="bg-white text-gray-600 "
            value="ProductBasicInformation"
          >
            Product Basic Information
          </option>
          <option
            className="bg-white text-gray-600 "
            value="Ingredients"
          >
            Ingredients
          </option>
          <option
            className="bg-white text-gray-600 "
            value="AllergensInformation"
          >
            Allergens Information
          </option>
          <option
            className="bg-white text-gray-600"
            value="NutritionalInformation"
          >
            Nutritional Information
          </option>
          <option
            className="bg-white text-gray-600"
            value="RecyclingInformation"
          >
            Recycling Information
          </option>
          <option className="bg-white text-gray-600" value="CookingInformation">
            Cooking Information
          </option>
          <option className="bg-white text-gray-600" value="ProductSuggestions">
            Product Suggestions
          </option>
        </select>
      </div>
      {StepRender}
    </div>
  );
};

export default AddProduct;
