import axios from "../../../../axios/axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hooks-helper";
import { Add } from "../../../../SVG/svg";
import IngredientsRow from "./TableRow/ingredientsRow";

const Ingredients = () => {
  const [ingredientsInfo, setIngredientsInfo] = useState([]);

  //   const [flag, setFlag] = useState(false);

  const defaultProductData = {
    product_id: localStorage.getItem("current_product_id"),
    name: "",
    quantity_contained: "",
    measurement_unit: "",
  };

  const [ingredientsData, setIngredientsData] = useForm(defaultProductData);

  useEffect(() => {
    (async () => {
      const id = localStorage.getItem("current_product_id");
      await axios
        .get(`/productIngredientInfo/${id}`)
        .then((response) => {
          setIngredientsInfo(response.data);
        })
        .catch((error) => console.log(error));
    })();
  }, []);

  //   console.log(effectFun());
  const AddRow = async () => {
    if (ingredientsData.name && ingredientsData.quantity_contained) {
      await axios
        .post(`/productIngredientInfo`, ingredientsData)
        .then(async (response) => {
          // setIngredientsInfo(response.data);
          //  console.log("date...",productInfo);
          const id = localStorage.getItem("current_product_id");
          await axios
            .get(`/productIngredientInfo/${id}`)
            .then((response) => {
              setIngredientsInfo(response.data);
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    } else {
      alert("enter all field data");
    }
  };

  
  const deleteItem = async (id) => {
    await axios
      .delete(`/productIngredientInfo/${id}`)
      .then(async (res) => {
        const product_id = localStorage.getItem("current_product_id");
        await axios
          .get(`/productIngredientInfo/${product_id}`)
          .then((response) => {
            setIngredientsInfo(response.data);
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="mt-10 border-gray-200 rounded-3xl bg-white ">
      <div className="-my-2 overflow-x-auto ">
        <div className="py-2 align-middle inline-block min-w-full  ">
          <div className="overflow-hidden rounded-3xl">
            <div className="text-lg font-poppins font-semibold text-gray-700 py-4 px-6 ">
              Ingredients Related Details
            </div>

            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-white">
                <tr>
                  <th></th>
                  <th scope="col" className="table-head">
                    Ingredients Name
                  </th>
                  <th scope="col" className="table-head">
                    Quantity contained
                  </th>
                  <th scope="col" className="table-head">
                    Measurement unit
                  </th>
                  <th scope="col" className="table-head">
                    {" "}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {ingredientsInfo.map((item) => (
                  <IngredientsRow
                    key={item.id}
                    ingredientsInfo={item}
                    deleteItem={deleteItem}
                  />
                ))}
                <tr key="1key">
                  <td> </td>
                  <td className="px-3 py-4 whitespace-nowrap">
                    <div className=" ">
                      <input
                        type="text"
                        name="name"
                        onChange={setIngredientsData}
                        className="input-field text-sm font-poppins text-gray-700"
                      />
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap ">
                    <div className=" ">
                      <input
                        name="quantity_contained"
                        type="number"
                        onChange={setIngredientsData}
                        className="input-field text-sm font-poppins text-gray-700"
                      />
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm font-poppins text-gray-700">
                    <div className="">
                      <select
                        className="input-field"
                        name="measurement_unit"
                        onChange={setIngredientsData}
                      >
                        <option>%</option>
                        <option>Kcal</option>
                        <option>Kg</option>
                        <option>gm</option>
                        <option>mg</option>
                        <option>mcg</option>
                        <option>Liter</option>
                        <option>ml</option>
                      </select>
                    </div>
                  </td>

                  <td className="px-3 py-4">
                    <button className="btn-default p-1" onClick={AddRow}>
                      <Add />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ingredients;
