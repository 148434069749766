import Layout from "../layout/layout";

const Dashbord = () => {
  return (
    <Layout>
        <h1 className="heading" >Dashbord</h1>
        <div className="ml-16 pl-5">
            <img src="/image/dashbord.png" alt="dashbord" />
        </div>
      {/* <div className="flex space-x-6 pt-20 " >
          <div className="flex-col space-y-6 w-1/2" >
               <div className="w-full h-80 rounded-3xl bg-white"></div> 
               
               <div className="flex space-x-6 w-full h-96">
               <div className="w-1/2 h-full bg-white rounded-3xl"></div> 
               <div className="w-1/2 h-full bg-white rounded-3xl"></div>
               </div> 
          </div>
          <div className="flex-col space-y-6 w-1/2" >
              <div className="h-52  grid grid-rows-2 grid-flow-col gap-4 " >
                  <div className="bg-blue-400 rounded-3xl ">
                      <p className="text-white p-2">Code Generated </p></div>
                  <div className="bg-white p-2 rounded-3xl" >2</div>
                  <div className=" bg-yellow-400 rounded-3xl" >
                  <p className="text-white p-2">Total Authentications </p>                      
                  </div>
                  <div className="bg-white p-2 rounded-3xl" >4</div>
              </div>
              <div className="bg-white w-full h-60 rounded-3xl" ></div>
              <div className="bg-white w-full h-60 rounded-3xl" > </div>
          </div>
      </div> */}
    </Layout>
  );
};

export default Dashbord;
