import React, { useEffect, useState } from "react";
import { useForm } from "react-hooks-helper";
import axios from "../../../../axios/axios";

import { Add } from "../../../../SVG/svg";
import Nutritional from "./TableRow/nutritional";
import Vitamins from "./TableRow/vitamins";

const NutritionalInformation = () => {
  const [nutritionalInfo, setNutritionalInfo] = useState([]);
  const [vitaminsInfo, setVitaminsInfo] = useState([]);

//   const [flag, setFlag] = useState(false);

  const defaultNutritionalData = {
    product_id: localStorage.getItem('current_product_id'),
    name: "",
    value_intake: "",
    precision_value: "mg",
    quantity_contained: "",
    measurement_unit: "mg",
    };

  const [nutritionalData, setNutritionalData] = useForm(defaultNutritionalData);

  const defaultVitaminsData = {
    product_id: localStorage.getItem("current_product_id"),
    name: "",
    value_intake: "",
    precision_value: "mg",
    quantity_contained: "",
    measurement_unit: "mg",
  };

  const [vitaminsData, setVitaminsData] = useForm(defaultVitaminsData);

  useEffect(() => {
    (async () => {
      const id = localStorage.getItem("current_product_id");
      await axios
        .get(`/productNutritionalInfo/${id}`)
        .then((response) => {
          setNutritionalInfo(response.data);
        }).catch( error => console.log(error));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const id = localStorage.getItem("current_product_id");
      await axios
        .get(`/productVitaminsInfo/${id}`)
        .then((response) => {
          setVitaminsInfo(response.data);
        }).catch( error => console.log(error));
    })();
  }, []);

  //   console.log(effectFun());
  const AddNutritionalRow = async () => {
    if (
      nutritionalData.name &&
      nutritionalData.measurement_unit &&
      nutritionalData.precision_value &&
      nutritionalData.value_intake &&
      nutritionalData.quantity_contained
    ) {
      await axios
        .post(
          `/productNutritionalInfo`,
          nutritionalData
        )
        .then(async (response) => {
          // setNutritionalInfo(response.data);
          //  console.log("date...",ProductInfo);
          const id = localStorage.getItem('current_product_id');
          await axios
            .get(`/productNutritionalInfo/${id}`)
            .then((response) => {
              setNutritionalInfo(response.data);
            }).catch( error => console.log(error));
        }).catch( error => console.log(error));
    } else {
      alert("enter all field data");
    }
  };

  const AddVitaminsRow = async () => {
    if (
      vitaminsData.name &&
      vitaminsData.measurement_unit &&
      vitaminsData.precision_value &&
      vitaminsData.value_intake &&
      vitaminsData.quantity_contained
    ) {
      await axios
        .post(`/productVitaminsInfo`, vitaminsData)
        .then(async (response) => {
          // setNutritionalInfo(response.data);
          //  console.log("date...",ProductInfo);
          const id = localStorage.getItem("current_product_id");
          await axios
            .get(`/productVitaminsInfo/${id}`)
            .then((response) => {
              setVitaminsInfo(response.data);
            }).catch( error => console.log(error));
        }).catch( error => console.log(error));
    } else {
      alert("enter all field data");
    }
  };

  const updateItemNutritional = (id) => {
    console.log("update called", id);
  };

  const deleteItemNutritional = async (id) => {
    await axios
      .delete(`/productNutritionalInfo/${id}`)
      .then(async (res) => {
        const Product_id = localStorage.getItem("current_product_id");
        await axios
          .get(`/productNutritionalInfo/${Product_id}`)
          .then((response) => {
            setNutritionalInfo(response.data);
          }).catch( error => console.log(error));
      }).catch( error => console.log(error));
  };

  const deleteItemVitamins = async (id) => {
    await axios
      .delete(`/productVitaminsInfo/${id}`)
      .then(async (res) => {
        const Product_id = localStorage.getItem("current_product_id");
        await axios
          .get(`/productVitaminsInfo/${Product_id}`)
          .then((response) => {
            setVitaminsInfo(response.data);
          }).catch( error => console.log(error));
      }).catch( error => console.log(error));
  };

  return (
    <div className="mt-10">
      <div className="-my-2 overflow-x-auto ">
        <div className="py-2 align-middle inline-block min-w-full  ">
          <div className="shadow overflow-hidden border-b border-gray-200 rounded-3xl">
            <div className="text-lg font-poppins font-semibold text-gray-700 py-4 px-6 bg-white">
              Nutritional Facts
            </div>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-white">
                <tr>
                <th scope="col" className="table-head">
                    <p className="pl-4">{"Nutrient Name"}</p>
                  </th>
                  <th scope="col" className="table-head">
                    {"Contains"}
                  </th>
                  <th scope="col" className="table-head">
                    {"Measurement Unit"}
                  </th>
                  <th scope="col" className="table-head">
                    {"PER quantity"}
                  </th>
                  <th scope="col" className="table-head">
                    {"Measurement unit"}
                  </th>
                  <th scope="col" className="table-head">
                    {" "}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {nutritionalInfo.map((item) => (
                  <Nutritional
                    key={item.id}
                    nutritionalInfo={item}
                    updateItem={updateItemNutritional}
                    deleteItem={deleteItemNutritional}
                    // flag={flag}
                  />
                ))}
                <tr key="1key">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="  ">
                      <input
                        type="text"
                        name="name"
                        onChange={setNutritionalData}
                        className="input-field text-sm font-poppins text-gray-700"
                      />
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap ">
                    <div className=" ">
                      <input
                        type="text"
                        name="value_intake"
                        onChange={setNutritionalData}
                        className="input-field text-sm font-poppins text-gray-700"
                      />
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm font-poppins text-gray-700">
                    <div className="">
                      <select
                        className="input-field"
                        name="precision_value"
                        onChange={setNutritionalData}
                      >
                        <option>%</option>
                        <option>Kcal</option>
                        <option>Kg</option>
                        <option>gm</option>
                        <option>mg</option>
                        <option>mcg</option>
                        <option>Liter</option>
                        <option>ml</option>
                      </select>
                    </div>
                  </td>
                  <td className="flex px-3 py-4 whitespace-nowrap ">
                    <div className="">
                      <input
                        type="text"
                        name="quantity_contained"
                        onChange={setNutritionalData}
                        className="input-field text-sm font-poppins text-gray-700"
                      />
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap">
                    <div className="">
                      <select
                        className="input-field"
                        name="measurement_unit"
                        onChange={setNutritionalData}
                      >
                        <option>%</option>
                        <option>Kcal</option>
                        <option>Kg</option>
                        <option>gm</option>
                        <option>mg</option>
                        <option>mcg</option>
                        <option>Liter</option>
                        <option>ml</option>
                      </select>
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap">
                    <button
                      className="btn-default p-1"
                      onClick={AddNutritionalRow}
                    >
                      <Add />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className=" my-4 overflow-x-auto ">
        <div className="py-2 align-middle inline-block min-w-full  ">
          <div className="shadow overflow-hidden border-b border-gray-200 rounded-3xl">
            <div className="text-lg font-poppins font-semibold text-gray-700 py-4 px-6 bg-white">
              Vitamins & Minerals
            </div>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-white">
                <tr>
                <th scope="col" className="table-head">
                    <p className="pl-4">{"Vitamins Name"}</p>
                  </th>
                  <th scope="col" className="table-head">
                    {"Contains"}
                  </th>
                  <th scope="col" className="table-head">
                    {"Measurement Unit"}
                  </th>
                  <th scope="col" className="table-head">
                    {"PER quantity"}
                  </th>
                  <th scope="col" className="table-head">
                    {"Measurement unit"}
                  </th>
                  <th scope="col" className="table-head">
                    {" "}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {vitaminsInfo.map((item) => (
                  <Vitamins
                    key={item.id}
                    vitaminInfo={item}
                    deleteItem={deleteItemVitamins}
                    // flag={flag}
                  />
                ))}
                <tr key="1key">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="  ">
                      <input
                        type="text"
                        name="name"
                        onChange={setVitaminsData}
                        className="input-field text-sm font-poppins text-gray-700"
                      />
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap ">
                    <div className=" ">
                      <input
                        type="number"
                        name="value_intake"
                        onChange={setVitaminsData}
                        className="input-field text-sm font-poppins text-gray-700"
                      />
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm font-poppins text-gray-700">
                    <div className="">
                    <select
                        className="input-field"
                        name="precision_value"
                        onChange={setVitaminsData}
                      >
                        <option>%</option>
                        <option>Kcal</option>
                        <option>Kg</option>
                        <option>gm</option>
                        <option>mg</option>
                        <option>mcg</option>
                        <option>Liter</option>
                        <option>ml</option>
                      </select>                      
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap ">
                    <div className="">
                      <input  type="number"
                        name="quantity_contained"
                        onChange={setVitaminsData} className="input-field text-sm font-poppins text-gray-700" />
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap">
                    <div className="">
                      <select
                        className="input-field"
                        name="measurement_unit"
                        onChange={setVitaminsData}
                      >
                        <option>%</option>
                        <option>Kcal</option>
                        <option>Kg</option>
                        <option>gm</option>
                        <option>mg</option>
                        <option>mcg</option>
                        <option>Liter</option>
                        <option>ml</option>
                      </select>
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap">
                    <button
                      className="btn-default p-1"
                      onClick={AddVitaminsRow}
                    >
                      <Add />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NutritionalInformation;
