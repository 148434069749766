import { useEffect, useState } from "react";
import QRCode from "qrcode";

import axios from "../../../axios/axios";
import Modal from "../../customElement/modal/userModal";
import Layout from "../layout/layout";
import GeneratedQRList from "./GeneratedQRList";

const SerializedQR = () => {
  const [productList, setProductList] = useState(
    <option>No Product Found</option>
  );

  const defaultData = {
    product_id: 0,
    mfr_id: localStorage.getItem("mfr_id"),
    product_price: 0,
    expiry_date: "",
    consume_within: 0,
    mfr_unit: " ",
    generated_date: "",
    verification_pin: "",
    StartingSerial: "",
    count: 0,
  };

  const [QRInfo, setQRInfo] = useState(defaultData);

  const setQRHandler = (event) => {
    setQRInfo({
      ...QRInfo,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    (async () => {
      const mfr_id = localStorage.getItem("mfr_id");

      await axios
        .get(`/productInfo/${mfr_id}`)
        .then((response) => {
          fetchProductList(response.data);
        })
        .catch((error) => console.log(error));
    })();
  }, []);

  const [QRList, setQRList] = useState();
  const [flag, setFlag] = useState(false);
  const NoQr = (
    <tr>
      <td className="py-3 px-4" colSpan="5">
        No Generated QR found!
      </td>
    </tr>
  );

  useEffect(() => {
    (async () => {
      const mfr_id = localStorage.getItem("mfr_id");

      await axios
        .get(`/generateQR/${mfr_id}`)
        .then((response) => {
          setQRList(response.data);
          setFlag(true);
          //   console.log(QRList);
        })
        .catch((error) => console.log(error));
    })();
  }, []);

  const fetchProductList = (List) => {
    const productList = List.map((item) => {
      return (
        <option
          key={item.product_basic_info[0].id}
          value={
            item.product_basic_info[0].id +
            ":" +
            item.product_basic_info[0].name +
            ":" + item.product_basic_info[0].manufactured_by
          }
        >
          {item.product_basic_info[0].name}
        </option>
      );
    });
    setProductList(productList);
  };

  const [productUnit, setProductUnit] = useState(false);
  const [productName, setProductName] = useState(false);

  const selectProductName = (event) => {
    const array = event.target.value.split(":");
    setQRInfo({ ...QRInfo, product_id: array[0] });

    setProductName(array[1]);

    const units = array[2].split(",");
    const unitList = units.map((item) => (
      <option key={item} value={item}>
        {item}
      </option>
    ));

    setProductUnit(unitList);
  };

  const generateQRHandler = () => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();

    const gDate = dd + "/" + mm + "/" + yyyy;
    const pin = QRInfo.StartingSerial + "#" + yyyy + mm + dd + "pin";

    generateQR(QRInfo, gDate, pin);
  };

  const generateQR = async (QRInfo, gDate, pin) => {
    await axios
      .post(`/generateQR`, {
        ...QRInfo,
        generated_date: gDate,
        verification_pin: pin,
        product_name: productName,
      })
      .then((response) => {
        // console.log(response.data);
        setQRList(response.data);
      })
      .catch((error) => console.log(error));
  };

  const [showModal, setShowModal] = useState(false);

  const modalCancelHandler = () => {
    setShowModal(false);
  };

  const modalSaveHandler = async () => {
    setShowModal(false);
  };

  const [qrCodeList, setQrCodeList] = useState();
  const [QrSrc, setQrSrc] = useState();

  const showModalHandler = async (id) => {
    console.log(id);

    await axios
      .get(`/qrCodeList/${id}`)
      .then((response) => {
        const aa = response.data.map((code, id) => {
          QRCode.toDataURL(code.qr)
            .then((url) => {
              setQrSrc(url);
            })
            .catch((err) => {
              console.error(err);
            });
          return (
            <div key={id} className="border-2 border-gray-700 p-1">
              <img src={QrSrc} alt={QrSrc} />
            </div>
          );
        });

        setQrCodeList(aa);
      })
      .catch((error) => console.log(error));

    setShowModal(true);
  };

  return (
    <Layout>
      <div className="flex-col">
        <div className="heading">Serialized QRs</div>
        <div className="flex-col mt-10 bg-white rounded-2lg py-8">
          <div className="text-lg text-gray-600 font-poppins font-semibold px-6 py-2">
            Generate QR Codes{" "}
          </div>
          <div className="grid grid-cols-3 gap-4 px-6">
            <div>
              <p className="py-2">Product</p>
              <select
                type="text"
                className="input-field"
                name="product"
                onChange={selectProductName}
              >
                {productList}
              </select>
            </div>
            <div>
              <p className="py-2">Product Price</p>
              <input
                className="input-field"
                type="number"
                name="product_price"
                onChange={setQRHandler}
              />
            </div>
            <div>
              <p className="py-2">Expiry Date</p>
              <input
                className="input-field"
                name="expiry_date"
                type="date"
                onChange={setQRHandler}
              />
            </div>
            <div>
              <p className="py-2">Consume Within</p>
              <input
                className="input-field"
                type="number"
                name="consume_within"
                onChange={setQRHandler}
              />
            </div>
            <div>
              <p className="py-2">Manufacturing Unit</p>
              <select
                type="text"
                className="input-field"
                name="mfr_unit"
                disabled={!productUnit}
                onChange={setQRHandler}
              >
                <option>Units</option>
                {productUnit}
              </select>
            </div>
            <div>
              <p className="py-2">Starting Serial</p>
              <input
                type="text"
                className="input-field"
                name="StartingSerial"
                onChange={setQRHandler}
              />
            </div>
            <div>
              <p className="py-2">Count*</p>
              <input
                className="input-field"
                type="number"
                name="count"
                onChange={setQRHandler}
              />
            </div>
            <div>
              <p className="py-2">Preview</p>
              <button
                className="btn-default px-4 py-3 w-full"
                onClick={generateQRHandler}
              >
                Generate QR Codes
              </button>
            </div>
          </div>
        </div>
        <div>
          <div className=" -mt-8 overflow-x-auto ">
            <div className="py-2 align-middle inline-block min-w-full divide-y divide-gray-200 ">
              <div className=" overflow-hidden border-b border-gray-200 rounded-lg divide-y divide-gray-200">
                <div className="bg-white p-6 "> </div>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-white">
                    <tr>
                      <th scope="col" className="table-head">
                        serial
                      </th>
                      <th scope="col" className="table-head">
                        Date
                      </th>
                      <th scope="col" className="table-head">
                        Verification Pin
                      </th>
                      <th scope="col" className="table-head">
                        Product Name
                      </th>
                      <th scope="col" className="table-head">
                        Download
                      </th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {flag ? (
                      <GeneratedQRList
                        QRList={QRList}
                        setQRList={setQRList}
                        showModal={showModalHandler}
                      />
                    ) : (
                      NoQr
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        cancel={modalCancelHandler}
        show={showModal}
        save={modalSaveHandler}
        title="QR"
      >
        <div className="text-gray-700">
          <div className="grid grid-cols-12 grid-rows gap-2">{qrCodeList}</div>
        </div>
      </Modal>
    </Layout>
  );
};

export default SerializedQR;
