
const sideLogo = () =>{
    return (
        <div className="w-1/4 sm:flex mob:flex bg-indigo-stegofy mob:w-auto mob:flex-row-reverse sm:flex-row-reverse mob:h-auto sm:h-auto sm:w-auto mob:justify-center">
        <h2 className="2xl:text-6xl xl:text-5xl mob:text-4xl lg:text-4xl md:text-5xl sm:text-5xl max-w-1/2 text-white font-poppins font-semibold p-2 ml-6 mob:ml-auto mob:mr-5 mt-8 mob:mt-0 mob:mb-10 mob:self-center sm:self-center sm:mb-14 sm:mt-0 sm:ml-auto sm:mr-5" id='stegofyText'>Stegofy</h2>
        <div className=" mt-48 ml-16 sm:mt-0 sm:ml-0 sm:items-end mob:mt-0 sm:ml-5 mob:ml-5 mob:w-2/6 sm:w-2/6">
          <img
            src="./logo-292.png"
            alt="logo"
            className=" "
          />
        </div>
      </div>
    );
}

export default sideLogo;