import React, { useState } from "react";
import axios from "../../../../../axios/axios";

import { Edit, DargDrop, DeleteSVG, Check } from "../../../../../SVG/svg";

const Nutritional = ({ nutritionalInfo, flag, deleteItem }) => {
  
  const [edit, setEdit] = useState(false);
  const [bgColor, setBgColor] = useState(null);

  const [nutritionalItem, setNutritionalItem] = useState({
    ...nutritionalInfo,
  });

  const updateItem = async () => {
      
      await axios
        .put(`/productNutritionalInfo/`, nutritionalItem)
        .then((response) => {
         // console.log(response.data);
        // alert("Item Updated successfully");
        })
        .catch((error) => console.log(error));

    setBgColor(null);
    setEdit(false);
  };

  const editHandler = () => {
    setBgColor("white");
    setEdit(true);
  };

  const nutritionalInfoHandler = (event) => {
    setNutritionalItem({
      ...nutritionalItem,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <tr key={nutritionalInfo.name}>
        <td className="px-2 py-4 whitespace-nowrap">
          <div className="flex font-semibold text-sm  font-poppins text-gray-700  ">
            <DargDrop />
            {/* <p className="pl-2"> {nutritionalItem.name}</p> */}
            <input
              disabled={!edit}
              type="text"
              name="name"
              value={nutritionalItem.name}
              onChange={nutritionalInfoHandler}             
            className="appearance-none font-semibold ml-4 p-1 rounded-md bg-white"
            />
          </div>
        </td>
        <td className="px-3 py-4 whitespace-nowrap ">
          <div className="">
            <input
              disabled={!edit}
              type="number"
              name="value_intake"
              value={nutritionalItem.value_intake}
              onChange={nutritionalInfoHandler}
              style={{ backgroundColor: bgColor }}
              className={`input-field text-sm font-poppins text-gray-700`}
            />
          </div>
        </td>
        <td className="px-3 py-4 whitespace-nowrap text-sm font-poppins text-gray-700">
          <div className="">
            <select
              disabled={!edit}
              style={{ backgroundColor: bgColor }}
              className="input-field"
              name="precision_value"
              value={nutritionalItem.precision_value}
              onChange = { nutritionalInfoHandler }
            >
              <option>%</option>
              <option>Kcal</option>
              <option>Kg</option>
              <option>gm</option>
              <option>mg</option>
              <option>mcg</option>
              <option>Liter</option>
              <option>ml</option>
            </select>
          </div>
        </td>
        <td className="px-3 py-4 whitespace-nowrap ">
          <div className="">
            <input
              disabled={!edit}
              style={{backgroundColor: bgColor}}
              type="number"
              name="quantity_contained"
              onChange={nutritionalInfoHandler}
              value={nutritionalItem.quantity_contained}
              className="input-field text-sm font-poppins text-gray-700"
            />
          </div>
        </td>
        <td className="px-3 py-4 whitespace-nowrap">
          <div className="">
            <select
            disabled={!edit}
            style={{backgroundColor: bgColor}}
              className="input-field"
              name="measurement_unit"
              value={nutritionalItem.measurement_unit}
              onChange = { nutritionalInfoHandler } >
              <option>%</option>
              <option>Kcal</option>
              <option>Kg</option>
              <option>gm</option>
              <option>mg</option>
              <option>mcg</option>
              <option>Liter</option>
              <option>ml</option>
            </select>
          </div>
        </td>
        <td className="flex py-4 px-2">
          {edit ? (
            <button
              className="btn-default p-1"
              onClick={ updateItem }
            >
              <Check />
            </button>
          ) : (
            <div
              className="p-1 rounded-md cursor-pointer hover:bg-gray-200"
              onClick={editHandler}
            >
              <Edit />
            </div>
          )}
          <div
            className="mx-1 p-1 rounded-md cursor-pointer hover:bg-gray-200"
            onClick={() => deleteItem(nutritionalItem.id)}
          >
            <DeleteSVG />
          </div>
        </td>
      </tr>
      {flag ? (
        <tr>
          <td colSpan="1"></td>
          <td colSpan="2">
            No Allergens Information found of this props.nutritionalInfo
          </td>
        </tr>
      ) : null}
    </>
  );
};

export default Nutritional;
