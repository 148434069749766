const Search = () => {
  return (
    <div
      className="relative items-stretch my-6 ml-7 mr-2"      
    >
      <span className="absolute inset-y-0 left-0 text-gray-500 font-normal text-center bg-transparent text-base items-center justify-center w-8 h-8 pt-2.5 pl-3">
        <img src="/icon/search.png" alt="search" />
      </span>
      <input
        placeholder="Search "
        className="input-field"
        style={{ paddingLeft: "40px" }}
      />
    </div>
  );
};

export default Search;
