import { useContext, useEffect, useState } from "react";
import Layout from "../layout/layout";
import AuthContext from "../../../context-store/auth-context";
import axios from "../../../axios/axios";

const Profile = () => {
  const data = {
    brandName: '',
    address: '',
    city: '',
    state: '',
    pinCode: '',
    supportNumber: '',
    businessType: '',
    industry: '',
    fssaiNumber: '',
    panNumber: '',
    gstNumber: '',
    };

  const [profileData, setProfileData] = useState(data);
  const [DataTrue,setDataTrue] = useState(true)
  const userCtx = useContext(AuthContext); 
  
  useEffect(() => {
    (async () => {
        const id = localStorage.getItem('mfr_id');        
      await axios.get(`/profile/${id}`).then((response) => {
        const data = response.data[0];
        setProfileData({
          brandName: data.brandName,
          address: data.address,
          city: data.city,
          state: data.state,
          pinCode: data.pinCode,
          supportNumber: data.supportNumber,
          businessType: data.businessType,
          industry: data.industry,
          fssaiNumber: data.fssaiNumber,
          panNumber: data.panNumber,
          gstNumber: data.gstNumber,
        })
        // console.log("date...",data.brandName);
        setDataTrue(true);
      }).catch( error => console.log(error));
    })();
  }, [userCtx.user_id]);

  return (
    <Layout>
      { DataTrue ? (<>
      <div className="flex flex-wrap mt-4">
        <p className="heading">Profile</p>
        <div className="flex-grow"> </div>
        <div className="btn-default flex w-32 px-7">
          <img src="/profile/edit.png" className="my-1 mt-3 h-3" alt="edit" />
          <button className="px-2 m-1">Edit</button>
        </div>
      </div>

      <div className="mt-4 flex space-x-6 ">
        <div className="w-64">
          <img
            className="w-full rounded-2lg"
            src="/profile/profile-photo.png"
            alt="logo"
            layout="responsive"
          />

          <button className="btn-default py-2 px-4 w-full mt-4 ">
            Change Picture
          </button>
        </div>

        <div className="flex space-x-6 flex-grow" >              
        <div className="rounded-3xl bg-white w-1/2">
          <div className="pt-3 px-8 pb-8">
            <h1 className="text-xl font-semibold text-gray-700">
              Brand Details
            </h1>
            <div className="mt-4">
              <p className="text-lg text-gray-600">Brand Name</p>
              <input
                className="input-field"
                disabled
                value={profileData.brandName}
              />
            </div>
            <div className="mt-4">
              <p className="text-lg text-gray-600">Address</p>
              <textarea
                className="textarea-field"
                disabled
                value={profileData.address}
              />
            </div>
            <div className="mt-4">
              <p className="text-lg text-gray-600">State</p>
              <input
                className="input-field"
                disabled
                value={profileData.state}
              />
            </div>
            <div className="mt-4">
              <div className="flex space-x-2">
                <div>
                  <p className="text-lg text-gray-600">City</p>
                  <input
                    className="input-field"
                    disabled
                    value={profileData.city}
                  />
                </div>
                <div>
                  <p className="text-lg text-gray-600">Pin Code</p>
                  <input
                    className="input-field"
                    disabled
                    value={profileData.pinCode}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4">
              <p className="text-lg text-gray-600">Support Number</p>
              <input
                className="input-field"
                disabled
                value={profileData.supportNumber}
              />
            </div>
          </div>
        </div>

        <div className="rounded-3xl bg-white w-1/2 h-4/5">
          <div className="pt-3 px-8 pb-8">
            <h1 className="text-xl font-semibold text-gray-700">
              Company Details
            </h1>
            <div className="mt-4">
              <p className="text-lg text-gray-600">GSTIN Number</p>
              <input
                className="input-field"
                disabled
                value={profileData.gstNumber}
              />
            </div>
            <div className="mt-4">
              <p className="text-lg text-gray-600">PAN Number</p>
              <input
                className="input-field"
                disabled
                value={profileData.panNumber}
              />
            </div>
            <div className="mt-4">
              <p className="text-lg text-gray-600">FSSAI Number</p>
              <input
                className="input-field"
                disabled
                value={profileData.fssaiNumber}
              />
            </div>

            <div className="mt-4">
              <div className="flex">
                <p className="text-lg text-gray-600">Business Type:</p>
                <h1 className="pl-3 text-lg text-gray-900 font-poppins">
                  {profileData.businessType}
                </h1>
              </div>
              <div className="flex mt-4">
                <p className="text-lg text-gray-600">Industry:</p>
                <h1 className="pl-3 text-lg text-gray-900 font-poppins">
                  {profileData.industry}
                </h1>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div> </>) : "loading"}
    </Layout>
  );
};

export default Profile;
