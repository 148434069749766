import React, { Fragment, useState } from "react";
// import { Upload } from '../../../SVG/svg';
import Layout from "../layout/layout";
import Filter from "../../customElement/filter";
import Search from "../../customElement/search";
import { DownArrow, Save, UpArrow, Upload } from "../../../SVG/svg";

const SupportWarranty = () => {
  const [up, setUp] = useState(false);
  const [show, setShow] = useState(null);
  const [content, setContent] = useState(null);

  const product = [
    {
      id: "ABC1234",
      subject: "Have some technical issues with the product",
      status: "On Hold",
      lastDate: "28-5-2021",
      support: "Suriya",
    },
    {
      id: "ABC1223",
      subject: "Regional Paradigm Technician",
      status: "on Progress",
      lastDate: "28-5-2021",
      support: "Suriya",
    },
    {
      id: "ABC1235",
      subject: "Not able to operate the device  properly",
      status: "Completed",
      lastDate: "28-5-2021",
      support: "Suriya",
    },
  ];

  const list = [
    {
      name: "Suriya",
      content:
        "I have Purchase the earphones but in that one earphone is not working properly and also not bale to hear it Properly, there is no any physical damage for the device but still facing the issues still the product have warranty need replacement.",
    },
    {
      name: "You",
      content:
        "Sorry for the inconvience casused to you we have arranged a pickup for the product, once we receive the product we will examine the product & will do the needful.",
    },
  ];

  const contentCard = (
    <tr key={Math.random()}>
      <td colSpan="1" className="m-2"></td>
      <td colSpan="5" className="m-2">
        <div className="flex space-x-3 text-xl text-gray-500 p-4 ml-2">
          <div>
            <img
              className="rounded-full w-20 h-20"
              alt=""
              src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60"
            />
          </div>
          <div className="w-9/12">
            <textarea className="textarea" placeholder="write text here..." />
          </div>
          <div className="flex-col -mt-2">
            <div>
              <button className="flex space-x-4 input">
                <div className="text-base text-gray-500">
                  <span className="pb-1 text-gray-500">
                    <Upload />
                  </span>
                  Upload
                </div>
                <span className="flex-col text-sm text-gray-400">
                  <p>.jpg</p> <p>.png</p> <p> .pdf</p>
                </span>
              </button>
            </div>
            <div className="w-full">
              <button className="flex space-x-2 btn-default py-2 px-10 w-36">
                <Save /> <p>Post</p>
              </button>
            </div>
          </div>
        </div>

        {list.map((post, idx) => (
          <div key={idx} className="px-4 py-2 my-2 ml-2">
            <div className="flex space-x-3">
              <div>
                <img
                  className="rounded-full w-16 h-16"
                  alt=""
                  src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60"
                />
              </div>
              <div className="w-9/12 pl-4">
                <p className="text-sm font-poppins font-semibold text-gray-600 py-1">
                  {post.name}
                </p>
                <p className="text-sm font-poppins font-medium text-gray-600">
                  {post.content}{" "}
                </p>
              </div>
            </div>
          </div>
        ))}
      </td>
    </tr>
  );

  const arrowHandler = (e) => {
    if (up && show && content) {
      setUp(false);
      setContent(null);
      setShow(null);
    } else if (!up) {
      setShow(e);
      setUp(true);
      setContent(contentCard);
    } else {
      setUp(false);
      setContent(null);
      setShow(null);
    }
  };

  return (
    <Layout>
      <div className="flex">
        <div className="heading">Support/Warranty Management </div>
      </div>
      <div className="flex flex-col mt-14">
        <div className="-my-2 overflow-x-auto ">
          <div className="py-2 align-middle inline-block w-full  ">
            <div className="shadow overflow-hidden border-b border-gray-200 rounded-layout divide-y-2 divide-gray-200">
              <div className="bg-white flex w-full">
                <div className=" flex-grow flex ">
                  <div className="flex-grow">
                  <Search />
                  </div>
                  <div className="text-center text-lg my-8 ml-1 mr-24 ">
                    {5} Tickets Total{" "}
                  </div>
                </div>
                <Filter />
                <div className="w-8" ></div>
              </div>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-white">
                  <tr>
                    <th scope="col" className="table-head appearance-none">
                      Ticket Id
                    </th>
                    <th scope="col" className="table-head">
                      Subject
                    </th>
                    <th scope="col" className="table-head">
                      Status
                    </th>
                    <th scope="col" className="table-head">
                      Last Date
                    </th>
                    <th scope="col" className="table-head">
                      Support
                    </th>
                    <th className="mx-3"> </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {product.map((product) => (
                    <Fragment key={product.id}>
                      <tr key={product.id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-700">
                            {product.id}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-700">
                            {product.subject}
                          </div>
                        </td>
                        <td className="flex px-6 py-4 whitespace-nowrap font-medium text-gray-700">
                          <span className="w-2 h-2 px-1 mt-2 inline-flex text-xs leading-5 font-semibold rounded-2lg bg-green-300 text-green-300"></span>
                          <p className="px-2">{product.status} </p>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-700">
                          {product.lastDate}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-700">
                          {product.support}
                        </td>
                        <td>
                          <div
                            onClick={() => arrowHandler(product.id)}
                            className="hover:text-gray-500"
                          >
                            {up && show === product.id ? (
                              <UpArrow />
                            ) : (
                              <DownArrow />
                            )}
                          </div>
                        </td>
                      </tr>
                      {show === product.id ? content : null}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SupportWarranty;
