import React, { useState } from "react";
import Product from "./product";
import AddProduct from "./addProduct";

import Layout from "../layout/layout";
import AddVariants from "./addVariants";

const Index = () => {
  const [flag, setFlag] = useState(false);
  const [viewProduct, setViewProduct] = useState(false);
  const [viewVariant, setViewVariant] = useState(false);
  const [variant, setVariant] = useState(false);

  const AddProductHandler = () => {
    setFlag(true);
  };

  const addVariantHandler = (product_id) => {
    localStorage.setItem("current_product_id", product_id);
    setFlag(true);
    setVariant(true);
  };

  const viewProductHandler = (product_id) => {
    localStorage.setItem("current_product_id", product_id);
    setViewProduct(true);
    setFlag(true);
  };

  const viewVariantHandler = (product_id, variant_id) => {
    localStorage.setItem("current_product_id", product_id);
    localStorage.setItem("current_variant_id", variant_id);
    setFlag(true);
    setVariant(true);
    setViewVariant(true);
  };

  const backClickHandler = () => {
    localStorage.removeItem("current_product_id");
    localStorage.removeItem("current_variant_id");
    setFlag(false);
    setVariant(false);
    setViewProduct(false);
    setViewVariant(false);
  };

  return (
    <Layout>
      {flag ? (
        variant ? (
          <AddVariants backClick={backClickHandler} view={viewVariant} />
        ) : (
          <AddProduct backClick={backClickHandler}  view = {viewProduct}/>
        )
      ) : (
        <Product
          click={AddProductHandler}
          addVariantHandler={addVariantHandler}
          viewProductHandler={viewProductHandler}
          viewVariantHandler={viewVariantHandler}
        />
      )}
    </Layout>
  );
};

export default Index;
