import React, { useEffect, useState } from "react";
import { useStep } from "react-hooks-helper";
import axios from "../../../axios/axios";

import VariantBasicInformation from "./addVariants/ProductBasicInformation";
import NutritionalInformation from "./addVariants/NutritionalInformation";

const AddVariants = (props) => {
  const steps = [
    { id: "NutritionalInformation" },
    { id: "VariantBasicInformation" },
  ];

  const { step, navigation } = useStep({
    steps,
    initialStep: 1,
  });

  const [saveType, setSaveType] = useState("VariantBasicInformation");

  const onSelectHandler = (e) => {
    navigation.go(e.target.value);
    setSaveType(e.target.value);
  };

  const defaultVariantData = {
    product_id: localStorage.getItem("current_product_id"),
    name: "",
    description: "",
    weight: "",
    weight_unit: "mg",
    batch_number: "",
    food_preference: "",
    video_url: "",
    image_name: "",
    manufactured_by: "",
  };

  const [variantData, setVariantData] = useState(defaultVariantData);
  const [mfrUnitList, setMfrUnitList] = useState([]);


  const setVariantDataHandler = (event) => {
    setVariantData({
      ...variantData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    const product_id = localStorage.getItem("current_product_id");
    const variant_id = localStorage.getItem("current_variant_id");

    if (props.view) {
      (async () => {
        await axios
          .get(`/variantItem/${variant_id}`)
          .then((response) => {
            setVariantData({
              ...response.data[0],
            });
            const aa = response.data[0].manufactured_by.split(',');
            setMfrUnitList(aa);
          })
          .catch((error) => console.log(error));
      })();
    } else {
      // Fetch data of parent product

      (async () => {
        await axios
          .get(`/productInfo/parent/${product_id}`)
          .then((response) => {
            setVariantData((prevState) => ({
              ...prevState,
              ...response.data[0],
              product_id: localStorage.getItem("current_product_id"),
            }));
          })
          .catch((error) => console.log(error));
      })();
    }
    
  }, [props.view]);

  const saveHandler = async () => {
    // add variant
    const product_id = localStorage.getItem("current_product_id");

    if (saveType === "VariantBasicInformation") {
      if (props.view) {
        await axios
          .put("/variant", variantData)
          .then(async (response) => {
            alert("Variant Data Updated");
          })
          .catch((error) => console.log(error));
      } else {
        await axios
          .post("/variant", variantData)
          .then(async (response) => {
            console.log(response.data);
            const variant_id = +response.data.id;
            localStorage.setItem("current_variant_id", variant_id);
          })
          .catch((error) => console.log(error));

        const variant_id = localStorage.getItem("current_variant_id");

        await axios
          .get(`/productNutritionalInfo/${product_id}`)
          .then((res) => {
            let Item = res.data;

            if (Item.length) {
              const nutritionList = Item.map((item) => {
                item = {
                  product_id: item.product_id,
                  name: item.name,
                  value_intake: item.value_intake,
                  precision_value: item.precision_value,
                  quantity_contained: item.quantity_contained,
                  measurement_unit: item.measurement_unit,
                  variant_id: +variant_id,
                };

                return item;
              });
              console.log("nutrition", nutritionList);

              axios
                .post("/variant/nutritionList", nutritionList)
                .then((res) => {
                  console.log(res);
                });
            } else {
              console.log("No nutrition Item found");
            }
          })
          .catch((err) => console.log(err));

        await axios
          .get(`/productVitaminsInfo/${product_id}`)
          .then((res) => {
            let Item = res.data;

            if (Item.length) {
              const VitaminsList = Item.map((item) => {
                item = {
                  product_id: item.product_id,
                  name: item.name,
                  value_intake: item.value_intake,
                  precision_value: item.precision_value,
                  quantity_contained: item.quantity_contained,
                  measurement_unit: item.measurement_unit,
                  variant_id: +variant_id,
                };

                return item;
              });

              axios.post("/variant/vitaminsList", VitaminsList).then((res) => {
                console.log(res);
              });
            } else {
              console.log("No Vitamins Item found !");
            }
          })
          .catch((err) => console.log(err));

        alert("Variant Variant added success");
        StepRender = <NutritionalInformation {...props1} />;
      }
    }
  };

  const props1 = {
    variantData,
    setVariantDataHandler,
    setMfrUnitList,
    mfrUnitList
  };

  let StepRender = <VariantBasicInformation {...props1} />;

  switch (step.id) {
    case "VariantBasicInformation":
      StepRender = <VariantBasicInformation {...props1} />;
      break;
    case "NutritionalInformation":
      StepRender = <NutritionalInformation {...props1} />;
      break;
    // case "Ingredients":
    //     StepRender = <Ingredients {...props1} />;
    //     break;

    default:
      break;
  }

  return (
    <div>
      <p onClick={props.backClick} className="cursor-pointer">
        {" "}
        {" < Back "}{" "}
      </p>
      <div className="flex px-2 py-4">
        <div className="heading ">
          {props.view ? "Update Variant Details" : "Add New Variant"}
        </div>
        <div className="flex-grow"></div>
        <button className="flex btn-default px-6 py-2 w-36 mr-3">
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>{" "}
          <p className="px-2" style={{ paddingTop: "1px" }}>
            Restart
          </p>
        </button>

        <div>
          <button
            className="flex btn-default px-8 py-2 w-36"
            onClick={saveHandler}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.3}
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <p className="px-2" style={{ paddingTop: "1px" }}>
              Save
            </p>
          </button>
        </div>
      </div>
      <div>
        <select
          type="text"
          className=" text-base text-white font-poppins rounded-lg px-6 py-2 focus:border-none focus:outline-none bg-indigo-stegofy"
          onChange={(e) => {
            onSelectHandler(e);
          }}
        >
          <option
            className="bg-white text-gray-600 "
            value="VariantBasicInformation"
          >
            Variant Basic Information
          </option>
          {/* <option
            className="bg-white text-gray-600 "
            value="Ingredients"
          >
            Ingredients
          </option>
          <option
            className="bg-white text-gray-600 "
            value="AllergensInformation"
          >
            Allergens Information
          </option> */}
          <option
            className="bg-white text-gray-600"
            value="NutritionalInformation"
          >
            Nutritional Information
          </option>
          {/* <option
            className="bg-white text-gray-600"
            value="RecyclingInformation"
          >
            Recycling Information
          </option>
          <option className="bg-white text-gray-600" value="CookingInformation">
            Cooking Information
          </option>
          <option className="bg-white text-gray-600" value="VariantSuggestions">
            Variant Suggestions
          </option> */}
        </select>
      </div>
      {StepRender}
    </div>
  );
};

export default AddVariants;
