import React from "react";
import Layout from "../layout/layout";

const createCampaign = () => {
  return (
    <Layout>
      <div className="heading mb-3">New Campaign</div>

      <div className="ml-2">
        <img src="/image/c-item1.png" alt="create campaign" />
        <img src="/image/c-item2.png" alt="create campaign" />
        <img src="/image/c-item3.png" alt="create campaign" />
      </div>
    </Layout>
  );
};

export default createCampaign;
