import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "../../../axios/axios";
import { Eye, EyeOff } from "../../../SVG/svg";
import AuthContext from "../../../context-store/auth-context";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const Step1 = ({ navigation }) => {

  const history = useHistory();
  const authCtx = useContext(AuthContext);

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const [type, setType] = useState("password");
  const [eye, setEye] = useState(true);

  const [emailerror, setemailerror] = useState(false)
  const [phoneerror, setphoneerror] = useState(false)

  const [statuspop, setstatuspop] = useState({
    open: false,
    text: "",
    color: "",
  });
 
  
  const setTypeHandler = () => {
    if (type === "password") {
      setType("text");
      setEye(false);
    } else {
      setType("password");
      setEye(true);
    }
  };

  const userInputHandler = (e) => {
    
    setEmail(e.target.value);
    const pattern1 =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      const pattern2 = /^\d+$/;

    if (pattern1.test(e.target.value) && isNaN(e.target.value)==true) {
      setemailerror(false);
    
      setEmail(e.target.value);   
      console.log(isNaN(email))
      setEmail(e.target.value);
     
    }
    else if( pattern2.test(e.target.value)&&e.target.value.length==10){
      setphoneerror(false);
      authCtx.phone_number=e.target.value;
     
    }
    else{
      setphoneerror(true);
      setemailerror(true);
    
    }
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };
  
  const LoginHandler = async () => {
   // user log in 
    const LoginData = {
        email: email,
        password: password,
        phone: authCtx.phone_number
    }
   
    if(authCtx.phone_number !== null || email !== null){

        if(password !== null){
            await axios
               .post("/login", LoginData)
               .then((response) => {                 
                      console.log(response);
                      const HashPass = response.data.password;
                    //   const userData = response.data.userData;
                      //const passwordData = "123";
                      console.log(HashPass);
                       
                       if(password === HashPass){
                           //console.log("success.....");
                         const email = response.data.email;
                         const mfrId = response.data.mfr_id;
                         const userName = response.data.name;
                         
                         authCtx.onLogin(userName, email, mfrId);
                         history.push("/dashbord");
                       }else{
                        console.log("failed");
                        alert("Invalid Password !!");
                       }
                                
                    //  const bytes = CryptoJS.AES.decrypt(passwordData, "Zz123!@#$Rcbt48k");
                    //  const passwordHashed = bytes.toString(CryptoJS.enc.Utf8);
                    //  console.log(passwordHashed)
                    // console.log(password , " :: " + bytes, "::" + passwordHashed );
                    //  if(password === passwordHashed){
                         
                    //     alert("Login success...");
                         
                    //      const email = response.data.email;
                    //      const mfrId = response.data.mfr_id;
                    //      const userName = response.data.name;
                         
                    //      authCtx.onLogin(userName, email, mfrId);
                    //      history.push("/dashbord");
                    //  }else{
                    //      alert("Invalid password !")
                    //  }
                 }).catch( error => console.log(error));
        }else{
            alert("Enter Password !");
        }
        
    }else{
        alert("Enter email OR Mobile number");
    }


  };

  const requestOTPHandler = () =>{

    if(authCtx.phone_number){
        navigation.go("step2");
    }else{
        alert("Enter mobile Number")
    }    
  }

  
  return (  
    <div className="mt-40 mob:mt-20 w-input md:w-auto mob:w-auto sm:my-auto sm:text-center sm:mt-6 mob:text-center">
      <p className="text-black md:text-4xl xl:text-4xl 2xl:text-4xl lg:text-4xl sm:text-3xl w-full font-poppins px-8 justify-items-center mb-14 mob:mb-8 mob:text-2xl ">
        Welcome to <strong className="font-semibold ">Stegofy</strong> 👋
      </p>
    
      <input
        type="text"
        name="email1"
        className={emailerror&&phoneerror&&Boolean(email)?"input mb-3 sm:w-3/4 mob:w-3/4 border-red-400 hover:border-red-400":Boolean(email)?"input mb-3 sm:w-3/4 mob:w-3/4 border-green-300 hover:border-green-300 focus:border-green-300 ":"input mb-3 sm:w-3/4 mob:w-3/4 border-grey-500 hover:border-grey-500 focus:border-indigo-300 "} 
        onChange={userInputHandler}
        // className="input mb-3 sm:w-3/4 mob:w-3/4"
        placeholder="Email / Phone"
      />
     
      
  
      <div className="relative sm:w-3/4 self-center flex w-full flex-wrap  mb-3 mob:justify-center sm:justify-center ml-auto mr-auto mob:w-3/4">
        <input
          type={type}
          onChange={passwordHandler} 
          placeholder="Password"
          // className={pswerror&&Boolean(password) ?"input sm:align-center border-red-500 hover:border-red-500":Boolean(password)?"input sm:align-center border-green-300 hover:border-green-300 focus:border-green-300 ":"input sm:align-center border-grey-500 hover:border-grey-500 focus:border-indigo-300 "} 
           className="input sm:align-center "
        />
        <span
          onClick={setTypeHandler}
          className="absolute inset-y-0 z-20 right-0 text-gray-600 h-full font-normal text-center items-center justify-center py-5 px-3"
        >
          {eye ? <Eye /> : <EyeOff />}
        </span>
      </div>
    

      <button className="btn btn-blue sm:w-3/4 mob:w-3/4 " onClick={LoginHandler}>
        {"Login"}
      </button>
      <p className="text-gray-500 font-semibold text-center pt-4">OR</p>
      <button className="btn-default text-lg w-full py-2 my-4 sm:w-3/4 mob:w-3/4" onClick={requestOTPHandler} >
        <p className="text-lg  "> Request OTP </p>
      </button>

      <p className="font-sans h-24 text-lg text-black p-4 text-center">
        {"New to Stegofy ? "}
        <button
          className="text-blue-600 font-semibold hover:text-blue-900 focus:outline-none"
          onClick={() => navigation.go("SignUpStep1")}
        >
          {"Create account"}
        </button>
      </p>
    </div>
  );
};

export default Step1;
