import Layout from "../layout/layout";
// import { Line, Doughnut } from "react-chartjs-2";

const analytics = () => {
//   const dataLine = {
//     labels: ["January", "February", "March", "April", "May", "June", "July"],
//     datasets: [
//       {
//         label: "My First dataset",
//         fill: false,
//         lineTension: 0.4,
//         backgroundColor: "rgba(75,192,192,0.4)",
//         borderColor: "#0D34FF", // line color
//         borderWidth: 6,
//         borderCapStyle: "butt",
//         borderDash: [],
//         borderDashOffset: 0.1,
//         borderJoinStyle: "miter",
//         pointBorderColor: "rgba(75,192,192,1)",
//         pointBackgroundColor: "#fff",
//         pointBorderWidth: 1,
//         pointHoverRadius: 5,
//         pointHoverBackgroundColor: "rgba(75,192,192,1)",
//         pointHoverBorderColor: "rgba(220,220,220,1)",
//         pointHoverBorderWidth: 2,
//         pointRadius: 1,
//         pointHitRadius: 10,
//         data: [0, 20, 10, 50, 20, 60, 30],
//       },
//       {
//         label: "My Second dataset",
//         fill: false,
//         lineTension: 0.4,
//         backgroundColor: "rgba(75,192,192,0.4)",
//         borderColor: "#f62525", // line color
//         borderWidth: 6,
//         borderCapStyle: "butt",
//         borderDash: [],
//         borderDashOffset: 0.1,
//         borderJoinStyle: "miter",
//         pointBorderColor: "rgba(75,192,192,1)",
//         pointBackgroundColor: "#fff",
//         pointBorderWidth: 1,
//         pointHoverRadius: 5,
//         pointHoverBackgroundColor: "rgba(75,192,192,1)",
//         pointHoverBorderColor: "rgba(220,220,220,1)",
//         pointHoverBorderWidth: 2,
//         pointRadius: 1,
//         pointHitRadius: 10,
//         data: [5, 60, 25, 70, 30, 60, 45],
//       },
//     ],
//   };

//   const dataDoughnut = {
//     labels: ["Green", "Blue", "Yellow", "Red"],
//     datasets: [
//       {
//         data: [200, 100, 150, 200],
//         backgroundColor: ["#09BD3C", "#0D34FF", "#FDBF20", "#FD5353"],
//         hoverBackgroundColor: ["#09BD3C", "#0D34FF", "#FDBF20", "#FD5353"],
//       },
//     ],
//   };

  return (
    <Layout>
      <div>
        <h1 className="heading"> Analytics </h1>
      </div>
      <div className="ml-6" >
      <img src="/image/analytics.png" alt="analytics" width="1000" />

      </div>

      {/* <div className="flex-col space-y-6 pt-12">
        <div className="h-32 grid grid-flow-col gap-6 ">
          <div className="bg-white rounded-3xl p-2">1</div>
          <div className="bg-white rounded-3xl p-2">2</div>
          <div className="bg-white rounded-3xl p-2">3</div>
          <div className="bg-white rounded-3xl p-2">4</div>
        </div>
        <div className="flex space-x-6 ">
          <div className="flex-col w-1/2 bg-white rounded-3xl p-3">
            <p className="font-semibold text-sm text-gray-400 uppercase mb-2 p-4">
              Generated code performance
            </p>
            <Line data={dataLine} width={400} height={200} />
          </div>
          <div className=" flex w-1/2 bg-white rounded-3xl p-3">
            <p className="font-semibold text-sm text-gray-400 uppercase mb-2 p-4">
              Product distribution
            </p>
            <div className=" w-1/2 h-40 pt-12">
              <Doughnut data={dataDoughnut} />
            </div>
          </div>
        </div>
        <div className="flex space-x-6">
          <div className="w-1/2 bg-white rounded-3xl"></div>
          <div className="w-1/2  flex-col space-y-6">
            <div className="w-full bg-white rounded-3xl p-3">
              <p className="font-semibold text-sm text-gray-400 uppercase mb-2 p-4">
                Customer spending's
              </p>
              <Line data={dataLine} width={400} height={200} />
            </div>
            <div className="flex w-full h-80 bg-white rounded-3xl p-2">
              <p className="font-semibold text-sm text-gray-400 uppercase mb-2 p-2">
              CUstomer Ratio
              </p>
              <div className="w-1/2 h-40 pt-7">
                <Doughnut data={dataDoughnut} />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </Layout>
  );
};

export default analytics;
