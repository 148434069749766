import React from 'react';
import axios from '../../../axios/axios';

import { DeleteSVG, Eye } from '../../../SVG/svg';

const GeneratedQRList = ({QRList, setQRList, showModal}) => {

    const deleteItem = async (id) =>{
        console.log(id)
        const mfr_id = localStorage.getItem("mfr_id")
        
        await axios
        .delete(`/generateQR/${mfr_id}/${id}`)
        .then((response) => {
           setQRList(response.data);
        })
        .catch((error) => console.log(error));
    };

    return (
        <>
         {QRList.map((qr) => (
                      <tr key={qr.id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-700">
                          {qr.id}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-700">
                          {qr.generated_date}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-700">
                          {qr.verification_pin}
                        </div>
                      </td>
                      <td className="flex px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                        <input
                          className="input-field"
                          value={qr.product_name}
                          disabled
                        />
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <button className="btn-default px-4 py-2">
                          Download
                        </button>
                      </td>
                      <td>
                        <div className="flex space-x-4">
                          <div className="p-1 rounded-md hover:bg-gray-300 text-gray-800" onClick={() => showModal(qr.id)}>
                            <Eye />
                          </div>
                          <div className="p-1 rounded-md hover:bg-gray-300" onClick={ () => { deleteItem(qr.id) } }>
                            <DeleteSVG />
                          </div>
                        </div>
                      </td>
                    </tr>
                    ))}
        </>
    );
};

export default GeneratedQRList;