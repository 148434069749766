import React, { useState } from "react";
import axios from "../../../../../axios/axios";

import { Edit, DargDrop, DeleteSVG, Check } from "../../../../../SVG/svg";

const IngredientsRow = ({ingredientsInfo, flag, deleteItem}) => {

    const [edit, setEdit] = useState(false);
  const [bgColor, setBgColor] = useState(null);

  const [ingredientItem, setNutritionalItem] = useState({
    ...ingredientsInfo,
  });

  const updateItem = async (id) => {
      
      await axios
        .put(`/productIngredientInfo/`, ingredientItem)
        .then((response) => {
         // console.log(response.data);
         
        })
        .catch((error) => console.log(error));

    setBgColor(null);
    setEdit(false);
  };

  const editHandler = () => {
    setBgColor("white");
    setEdit(true);
  };

  const ingredientsInfoHandler = (event) => {
    setNutritionalItem({
      ...ingredientItem,
      [event.target.name]: event.target.value,
    });
  };

  return (
      <>
      <tr>
        <td className="pl-2 py-4 whitespace-nowrap">
          <DargDrop />
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
        <input
              disabled={!edit}
              type="text"
              name="name"
              value={ingredientItem.name}
              onChange={ingredientsInfoHandler}             
            className="appearance-none font-semibold ml-4 p-1 rounded-md bg-white"
            />
        </td>
        <td className="px-3 py-4 whitespace-nowrap ">
        <div className="">
            <input
              disabled={!edit}
              style={{backgroundColor: bgColor}}
              type="number"
              name="quantity_contained"
              onChange={ingredientsInfoHandler}
              value={ingredientItem.quantity_contained}
              className="input-field text-sm font-poppins text-gray-700"
            />
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-poppins text-gray-700">
        <div>
        <select
            disabled={!edit}
            style={{backgroundColor: bgColor}}
              className="input-field"
              name="measurement_unit"
              value={ingredientItem.measurement_unit}
              onChange = { ingredientsInfoHandler } >
              <option>%</option>
              <option>Kcal</option>
              <option>Kg</option>
              <option>gm</option>
              <option>mg</option>
              <option>mcg</option>
              <option>Liter</option>
              <option>ml</option>
            </select>
            </div>
        </td>

        <td className="flex py-4 whitespace-nowrap">
        {edit ? (
            <button
              className="btn-default p-1"
              onClick={() => updateItem(ingredientItem.id)}
            >
              <Check/>
            </button>
          ) : (
            <div
              className="p-1 rounded-md cursor-pointer hover:bg-gray-200"
              onClick={editHandler}
            >
              <Edit />
            </div>
          )}
          <span className="p-1 rounded-md cursor-pointer hover:bg-gray-200" onClick={() => deleteItem(ingredientsInfo.id)}>
            <DeleteSVG />
          </span>
        </td>
      </tr>
      {flag ? (
        <tr>
          <td colSpan="1"></td>
          <td colSpan="2">
            No Allergens Information found of this props.product
          </td>
        </tr>
      ) : null}
    </>
  );
};

export default IngredientsRow;
