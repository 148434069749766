import React, { useEffect, useState } from "react";
import axios from "../../../axios/axios";

import Export from "../../customElement/export";
import Filter from "../../customElement/filter";
import Grid from "../../customElement/grid";
import Search from "../../customElement/search";
import Accordion from "./Accordion/accordionProduct";
import { Upload } from "../../../SVG/svg";

const Products = ({click, addVariantHandler, viewProductHandler, viewVariantHandler }) => {
  
  const [productInfo, setProductInfo] = useState([]);
  const  mfr_id = localStorage.getItem("mfr_id");

  useEffect(() => {
    (async () => {      
      await axios.get(`/productInfo/${mfr_id}`)
        .then((response) => {
          setProductInfo(response.data);          
        }).catch( error => console.log(error));
    })();
  }, [mfr_id]);

  const deleteHandler = async(product_id) =>{
    // console.log("delete", product_id, mfr_id)

    if (window.confirm("Do You Want to Delete Product")) {
        await axios
            .delete(`/product/parent/${product_id}/${mfr_id}`)
            .then((response) => {
             setProductInfo(response.data);
              //console.log(response);
            })
            .catch((error) => console.log(error));
            
            alert("Product is deleted Successfully")
        } else {
          alert("Product is not deleted")
        }
  }

  return (
    <>
      <div className="flex">
        <div className="heading">Products</div>
        <button
          className="flex btn-default w-48 py-2 pl-4 ml-10"
          onClick={click}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <p className="px-2" style={{ paddingTop: "2px" }}>
            Add Products
          </p>
        </button>
        <button className="flex btn-default w-48 py-2 px-3 ml-4">
          <Upload />
          <p className="pl-2" style={{ paddingTop: "2px" }}>
            Import Catalouge
          </p>
        </button>
      </div>
      <div className="flex flex-col mt-14">
        <div className="-my-2 overflow-x-auto ">
          <div className="py-2 align-middle inline-block min-w-full  ">
            <div className="shadow overflow-hidden border-b border-gray-200 rounded-layout divide-y-2 divide-gray-200">
              <div className="bg-white flex w-full">
                <div className="flex-grow ">
                  <Search />
                </div>
                <Export />
                <Filter />
                <Grid />
                <div className="w-6"></div>
              </div>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-white">
                  <tr>
                    <th scope="col" className="pl-0">
                      <input type="checkbox" className="w-5 h-5" />
                    </th>
                    <th scope="col" className="table-head">
                      Product code
                    </th>
                    <th scope="col" className="table-head">
                      Product Name
                    </th>
                    <th scope="col" className="table-head">
                      Quantity
                    </th>
                    <th scope="col" className="table-head">
                      Variant
                    </th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {productInfo.map((productInfo,idx) => (
                    <Accordion
                      key={idx}
                      productInfo={productInfo}
                      viewProductHandler={viewProductHandler}
                      viewVariantHandler={viewVariantHandler}
                      addVariantHandler={ addVariantHandler }
                      deleteHandler = {deleteHandler}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
