import React,{useState} from 'react';
import Modal from '../../customElement/modal/userModal';
import axios from "../../../axios/axios";

import {Edit,DeleteSVG} from "../../../SVG/svg";

const UnitRow = ({item, setUnitList }) => {
    
    const mfr_id = +localStorage.getItem("mfr_id");
    const [showModal, setShowModal] = useState(false);
    const [unit, setUnit] = useState({
        ...item
      });
       
    const setUnitHandler = (event) => {
        setUnit({
        ...unit,
        [event.target.name]: event.target.value,
        });
    };
  const modalCancelHandler = () => {
    setShowModal(false);
  };

  const modalSaveHandler = async () => {
      await axios
      .put(`/units`, unit)
      .then((response) => {
        setUnitList(response.data);        
        // console.log("date post", unitList);
      })
      .catch((error) => console.log(error));      
    setShowModal(false);
  };


      const deleteHandler = async(id)=>{
          console.log(id,mfr_id)
        await axios
        .delete(`/units/${id}/${mfr_id}`)
        .then((response) => {
          setUnitList(response.data);      
        })
        .catch((error) => console.log(error));
       }
    
      const UpdateHandler = async (id)=>{
          setShowModal(true);
      
        const unitData = { ...unit,id:id }
        await axios
        .put(`/units`,unitData)
        .then((response) => {
          setUnitList(response.data);      
        })
        .catch((error) => console.log(error));
      }
    

    return (
        <>
        <tr key={unit.id}>
                      <td className="pl-4 py-4 whitespace-nowrap">
                        <div>
                          <input type="checkbox" className=" w-5 h-5" />
                        </div>
                      </td>

                      <td className="pl-4 py-4 whitespace-nowrap">
                        <div className="items-center">
                          <div className="text-sm font-bold text-gray-600">
                           {unit.name} 
                          </div>
                        </div>
                      </td>
                      <td className="pl-4 py-4 whitespace-nowrap  text-gray-600">
                        {unit.address}
                      </td>
                      <td className="flex space-x-3 py-4 whitespace-nowrap">
                        <div
                          className="p-1 rounded-md cursor-pointer hover:bg-gray-200"
                          onClick={() => UpdateHandler(unit.id)}
                        >
                          <Edit/>
                        </div>
                        <span
                          className="p-1 rounded-md cursor-pointer hover:bg-gray-200"
                          onClick={() => deleteHandler(unit.id)}
                        >
                          <DeleteSVG />
                        </span>
                      </td>
                    </tr>
                    <Modal
                    cancel={modalCancelHandler}
                    show={showModal}
                    save={modalSaveHandler}
                    title="Add New Unit"
                  >
                    <div className="text-gray-700">
                      <div className="grid grid-cols-1 gap-4">
                        <div className="lg:w-99 sm:w-auto">
                          <p className="py-2">Name</p>
                          <input
                            className="input-field"
                            name="name"
                            value={unit.name}
                            onChange={setUnitHandler}
                          />
                        </div>
                        <div>
                          <p className="py-2">Address</p>
                          <textarea
                            className="textarea-field"
                            name="address"
                            value={unit.address}
                            onChange={setUnitHandler}
                          />
                        </div>
                      </div>
                    </div>
                  </Modal>
                  </>
    );
};

export default UnitRow;