import React, { useState } from "react";
import axios from "../../../../../axios/axios";

import {Add, Edit, DargDrop, DeleteSVG, Check } from "../../../../../SVG/svg";

const Cooking = ({ cookingInfo, flag, deleteItem}) => {

    const [edit, setEdit] = useState(false);
    const [bgColor, setBgColor] = useState(null);
  
    const [cookingItem, setCookingItem] = useState({
      ...cookingInfo,
    });
  
    const updateItem = async () => {
        await axios
          .put(`/productCookingInfo/`, cookingItem)
          .then((response) => {
           // console.log(response.data);
          // alert("Item Updated successfully");
          })
          .catch((error) => console.log(error));
  
      setBgColor(null);
      setEdit(false);
    };
  
    const editHandler = () => {
      setBgColor("white");
      setEdit(true);
    };
  
    const cookingInfoHandler = (event) => {
      setCookingItem({
        ...cookingItem,
        [event.target.name]: event.target.value,
      });
    };

  const video = [
    { alt: "itm1", image: "/icon/video.png" },
    { alt: "itm2", image: "/icon/video.png" },
    { alt: "itm3", image: "/icon/video.png" },
  ];

  const image = [
    { alt: "itm1", image: "/icon/image.png" },
    { alt: "itm2", image: "/icon/image.png" },
    { alt: "itm3", image: "/icon/image.png" },
  ];

  return (
    <>
      <tr key={cookingInfo.id}>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="flex font-semibold text-sm font-poppins text-gray-700  ">
            <DargDrop />
            <input
              disabled={!edit}
              type="number"
              name="step_no"
              value={cookingItem.step_no}
              onChange={cookingInfoHandler}             
            className=" w-20 appearance-none font-semibold ml-4 p-1 rounded-md bg-white"
            />
          </div>
        </td>
        <td className="px-3 py-4 whitespace-nowrap ">
          <div className="">
             <input
              disabled={!edit}
              type="text"
              name="cooking_step"
              value={cookingItem.cooking_step}
              onChange={cookingInfoHandler}
              style={{ backgroundColor: bgColor }}
              className={`input-field text-sm font-poppins text-gray-700`}
            />
          </div>
        </td>
        <td className="px-3 py-4 whitespace-nowrap text-sm font-poppins text-gray-700">
          <div className="flex space-x-1 overflow-hidden">
            {video.map((item, id) => (
              <div
                key={id}
                className="inline-block h-12 w-12 px-2 py-4 rounded-lg ring-2 ring-white bg-green-400 "
              >
                <img alt={item.alt} src={item.image} />
              </div>
            ))}
            <button className="btn-default h-12 w-12 pl-3">
              <Add />
            </button>
          </div>
        </td>
        <td className="px-3 py-4 whitespace-nowrap ">
          <div className="flex space-x-1 overflow-hidden">
            {image.map((item, id) => (
              <div
                key={id}
                className="inline-block h-12 w-12 rounded-lg ring-2 ring-white"
              >
                <img alt={item.alt} src={item.image} />
              </div>
            ))}
            <button className="btn-default h-12 w-12 pl-3">
              <Add />
            </button>
          </div>
        </td>
        <td className="flex space-x-1 py-4 whitespace-nowrap">
        {edit ? (
            <button
              className="btn-default p-1"
              onClick={ updateItem }
            >
              <Check/>
            </button>
          ) : (
            <div
              className="p-1 rounded-md cursor-pointer hover:bg-gray-200"
              onClick={editHandler}
            >
              <Edit />
            </div>
          )}
          <div className="p-1 rounded-md cursor-pointer hover:bg-gray-200" onClick={() => deleteItem(cookingInfo.id)}>
            <DeleteSVG />
          </div>
        </td>
      </tr>
      {flag ? (
        <tr>
          <td colSpan="1"></td>
          <td colSpan="2">
            No Allergens Information found of this cookingInfo
          </td>
        </tr>
      ) : null}
    </>
  );
};

export default Cooking;
