import { useFilePicker } from "use-file-picker";
import React from "react";
import { uploadFile } from 'react-s3';


const S3_BUCKET ='stegofy-storage-001';
const REGION ='ap-south-1';
const ACCESS_KEY ='AKIAR365CKJKKBWQOU4S';
const SECRET_ACCESS_KEY ='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';

const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
}

export default function ImagePicker(props) {

    // const [selectedFile, setSelectedFile] = useState(null);

    // const handleFileInput = (e) => {
    //     setSelectedFile(e.target.files[0]);
    //     console.log(selectedFile,e.target.files[0])
    //     // uploadFile(selectedFile, config)
    //     //     .then(data => console.log(data))
    //     //     .catch(err => console.error(err))
    // }


    const handleUpload = async (file) => {
        uploadFile(file, config)
            .then(data => console.log(data))
            .catch(err => console.error(err))
    }


  const [openFileSelector, { filesContent, loading, errors}] =
    
  useFilePicker({
      multiple: true,
      readAs: "ArrayBuffer", // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
      // accept: '.ics,.pdf',
      accept: [".png", ".jpg"],
      limitFilesConfig: { min: 1, max: 1 },
      // minFileSize: 1, // in megabytes
      // maxFileSize: 1,
      // maxImageHeight: 1024, // in pixels
      // minImageHeight: 1024,
      // maxImageWidth: 768,
      // minImageWidth: 768
      // readFilesContent: false, // ignores file content
    });

  if (errors.length) {
    return (
      <div>
        <button onClick={() => openFileSelector()}>
          Something went wrong, retry!{" "}
        </button>
        {errors[0].fileSizeTooSmall && "File size is too small!"}
        {errors[0].fileSizeToolarge && "File size is too large!"}
        {errors[0].readerError && "Problem occured while reading file!"}
        {errors[0].maxLimitExceeded && "Too many files"}
        {errors[0].minLimitNotReached && "Not enought files"}
      </div>
    );
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  let filename = null;
  if(!!filesContent.length){

    filename = filesContent[0].content;
    console.log(filename);

      return (
        <div className="flex mt-2">
        <div className="w-28">
          {/* If readAs is set to DataURL, You can display an image */}
          {!!filesContent.length && (
            <img
            type="file"
            className="w-28 rounded-lg shadow-md "
              src={filesContent[0].content}
              alt={filesContent[0].content}
                           
            />
          )}
  
          {/* {plainFiles.map((file) => (
            <div key={file.name}>{file.name}</div>
          ))} */}
        </div>
  
        <button className="btn-default ml-4" onClick={() => openFileSelector()}>{props.children}</button>
        <button onClick={() => handleUpload(filename)} >S3 upload</button>
      </div>
      )
  }

  return (
    <div className="flex space-x-10 mt-2">
          <button className="btn-default ml-4" onClick={() => openFileSelector()}>{props.children}</button>          
    </div>
  );
}
