import Layout from "../layout/layout";

const loyalty = () => {
  return (
    <Layout>
      <div className="heading" >Loyalty</div>

    <img src="/image/loyalty.png" alt="Loyalty" />
    </Layout>
  );
};

export default loyalty;
